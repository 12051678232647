import * as React from 'react';

import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core/styles";
import {
  Box, Button, FormControl, Hidden, InputAdornment, LinearProgress, Menu, MenuItem, TextField,
  Tooltip, Typography
} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import CustomiseColumnsIcon from "@material-ui/icons/ViewColumn";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import {useState} from "react";

// the style for the table toolbar
const styles = (theme: Theme) => createStyles({
  toolbar: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    display: 'flex',
  },
  middle: {
    margin: 'auto',
  },
  applicationCount: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      visibility: "hidden"
    },
  },
  downloadButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  iconText: {
    paddingLeft: theme.spacing(1),
  }
});

/** the properties of the table toolbar */
interface IToolbarProps extends WithStyles<typeof styles> {
  /** the table's title */
  title: string;
  /** The handler for when a search term is entered. */
  onSearchChanged: (searchText:string) => void;
  /** Callback for when choose columns button is clicked. */
  onChooseColumns?: () => void;
  /**
   * Callback for when the download data button is clicked. All columns should be included when
   * `all` is true, otherwise only the columns selected in the current view should be included.
   */
  onDownload?: (all: boolean) => void;
  /** Is a loading operation in progress? */
  isLoading?: boolean;
}

/**
 * The applications table toolbar component. A search is invoked when 'Enter' is pressed.
 */
export const ApplicationsPageTableToolbar = withStyles(styles)(({
    title,
    onSearchChanged, onChooseColumns = () => null, onDownload = () => null, isLoading,
    classes
}: IToolbarProps) => {
  const [text, setText] = useState('');
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = (
    React.useState<null | HTMLElement>(null)
  );
  const downloadMenuOpen = Boolean(downloadMenuAnchorEl);
  const handleOpenDownloadMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDownloadMenuAnchorEl(event.currentTarget);
  };
  const handleCloseDownloadMenu = () => {
    setDownloadMenuAnchorEl(null);
  };
  const handleOnDownload = (all: boolean) => {
    handleCloseDownloadMenu();
    onDownload(all);
  };

  return <>
    <Box width="100%" visibility={isLoading ? "visible" : "hidden"}>
      <LinearProgress />
    </Box>
    <div className={classes.toolbar}>
      <div>
        <Typography id={`${title}-page`} variant="h6">{title}</Typography>
      </div>
      <div className={classes.middle} />
      <Box display="flex" flexDirection="row" alignItems="center">
        <FormControl>
          <TextField
            id="search-bar"
            value={text}
            placeholder="Search"
            type="search"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onSearchChanged(text)
              }
            }}
            onChange={(event) => setText(event.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search fontSize='small'/></InputAdornment>
            }}
          />
        </FormControl>

        <FormControl className={classes.downloadButton}>
          <Tooltip title='Download applicant data in CSV format' aria-label='download'>
            <Button
              id="download-button"
              aria-controls="download-menu"
              aria-haspopup="true"
              aria-expanded={downloadMenuOpen ? 'true' : undefined}
              variant="outlined"
              onClick={
                (event: React.MouseEvent<HTMLButtonElement>) => handleOpenDownloadMenu(event)
              }
            >
              <DownloadIcon />
              <Hidden smDown>
                <Typography variant="button" className={classes.iconText}>Download</Typography>
              </Hidden>
            </Button>
          </Tooltip>
        </FormControl>
        <Menu
          id="download-menu"
          aria-labelledby="download-button"
          data-role="menu"
          anchorEl={downloadMenuAnchorEl}
          open={downloadMenuOpen}
          onClose={handleCloseDownloadMenu}
        >
          <MenuItem
            data-role="menuItem"
            data-text="Download currently selected columns"
            onClick={() => handleOnDownload(false)}
          >
            Download currently selected columns
          </MenuItem>
          <MenuItem
            data-role="menuItem"
            data-text="Download all columns"
            onClick={() => handleOnDownload(true)}
          >
            Download all columns
          </MenuItem>
        </Menu>

        <Tooltip title="Customise columns" aria-label="customise columns">
          <Button id="choose-columns-button" variant="outlined" onClick={() => onChooseColumns()}>
            <CustomiseColumnsIcon />
              <Hidden smDown>
                <Typography variant="button" className={classes.iconText}>Customise columns</Typography>
              </Hidden>
          </Button>
        </Tooltip>
      </Box>
    </div>
  </>;
});

export default ApplicationsPageTableToolbar;
