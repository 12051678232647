import * as React from 'react';

import {Box, Divider} from '@material-ui/core';
import {
  NavigationPanel as NavigationPanelRoot,
  NavigationPanelAvatar, NavigationPanelLogo,
  NavigationPanelSection, NavigationPanelSectionAnchor, NavigationPanelSectionLink,
  NavigationPanelFooter, NavigationPanelFooterLink
} from "./navigation-panel";

import LogoImage from '../images/logo.svg';
import { useLocation } from 'react-router-dom';
import {ProfileContext} from '../providers/ProfileProvider';
import signInUrl from "../signInUrl";

// The project's source code repository (used in the navbar's footer)
const PROJECT_REPO = "https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi"

// The URL for the feedback form
const FEEDBACK_FORM = "https://forms.office.com/Pages/ResponsePage.aspx?id=RQSlSfq9eUut41R7TzmG6Y5KG8YYTZNAhCXR5rPAUvJUODhEQVdYQzhYWVNCRTdIVExQU0daMDFZTC4u"

/**
 * Utility function to return props for ListItem when it is used as a Link to other parts of the
 * application.
 */
const navLinkProps = (location: any, to: string, text: string) => {
  const selected = (to === location.pathname);
  return {
    to, text, selected, 'dataRole': 'link'
  };
};


/**
 * Content of the side navigation drawer.
 */
export const NavigationPanel = () => {
  const profile = React.useContext(ProfileContext);
  const location = useLocation();
  const sections = [];

  const hasGroup = (group: string) => {
    return profile?.groups.map(g => g.name).includes(group);
  };

  if (profile === null) {
    // No profile yet
  } else if (profile.isAnonymous) {
    sections.push(
      <NavigationPanelSection dataRole="accountDrawer">
        <NavigationPanelSectionAnchor link={ signInUrl('/') } text="Sign in" dataRole="signIn"/>
      </NavigationPanelSection>
    );
  } else {
    sections.push(
      <NavigationPanelAvatar profile={profile}/>,
      <NavigationPanelSection dataRole="navDrawer">
        <NavigationPanelSectionLink {...navLinkProps(location, "/summary", "Summary")}/>
        <NavigationPanelSectionLink {...navLinkProps(location, "/applications", "Applications")}/>
        <NavigationPanelSectionLink {...navLinkProps(location, "/charts", "Charts")}/>
        {
          (profile.invitableRoles.length > 0) && (
            <NavigationPanelSectionLink {...navLinkProps(location, "/invitations", "Invitations")}/>
          )
        }
        {
          (hasGroup("Central Admissions Officers")) && (
            <NavigationPanelSectionLink {...navLinkProps(location, "/caoDashboard", "CAO Dashboard")}/>
          )
        }
      </NavigationPanelSection>,
      <NavigationPanelSection dataRole="accountDrawer">
        <NavigationPanelSectionAnchor link="/accounts/logout" text="Sign out" dataRole="signOut"/>
      </NavigationPanelSection>,
    )
  }

  return (
    <NavigationPanelRoot>
      <NavigationPanelLogo logoImage={LogoImage} logoImageAlt="Subject Moderation"/>
      <Divider />
      <Box flexGrow={1}>{
        sections.map((section, index) => (
          <React.Fragment key={ index }>
            { (index !== 0) ? <Divider /> : null }
            { section }
          </React.Fragment>
        ))
      }</Box>
      <Divider />
      <NavigationPanelFooter>
        <NavigationPanelFooterLink link={ PROJECT_REPO + "/wikis/About" } text="About"/>
        <NavigationPanelFooterLink link={ PROJECT_REPO + "/wikis/Terms-of-Service" } text="Terms of Service"/>
        <NavigationPanelFooterLink link={ PROJECT_REPO } text="Developers"/>
        <NavigationPanelFooterLink link={ FEEDBACK_FORM } text="Feedback"/>
      </NavigationPanelFooter>
    </NavigationPanelRoot>
  )
};

export default NavigationPanel;
