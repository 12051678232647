/** fixed guids for well known assessment types */
export const ASSESSMENT_TYPE_GUID_POLAR4 = '9a54fdbc-c4bf-11e9-8530-5fe11bf7e1eb';
export const ASSESSMENT_TYPE_GUID_AT_INTERVIEW_1 = '79b39a33-7f5d-4762-92c6-3a8b11026307';
export const ASSESSMENT_TYPE_GUID_AT_INTERVIEW_2 = 'ab7082dd-f574-42f6-b17f-e319dd25c6c7';
export const ASSESSMENT_TYPE_GUID_COLLEGE_RANK = 'bfa29eea-fd3a-4f35-a881-04bb981bcea7';
export const ASSESSMENT_TYPE_INTERVIEW_1 = '38f152f4-c4c5-11e9-8719-2735c3ff7e86';
export const ASSESSMENT_TYPE_INTERVIEW_2 = '4ca9f8f0-c4c5-11e9-ab8e-0f91331ef974';
export const ASSESSMENT_TYPE_INTERVIEW_3 = '51639cd4-c4c5-11e9-b0b3-237eb7ea2870';
export const ASSESSMENT_TYPE_INTERVIEW_4 = '55be9b26-c4c5-11e9-95fe-23ed5c46d5c6';
export const ASSESSMENT_TYPE_INTERVIEW_5 = '5a01b650-c4c5-11e9-b747-1fe2f7da7512';
export const ASSESSMENT_TYPE_INTERVIEW_6 = 'bf93093d-cf85-423b-bdcf-88cc3b47b25b';
export const ASSESSMENT_TYPE_INTERVIEW_AVERAGE = 'e839b359-ed35-4e36-a1cd-a285b93321fd';
