import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export interface INavigationPanelSectionAnchorProps {
    /** The `href` for the anchor */
    link: string
    /** The anchor's text */
    text: string
    /** Renders as `data-role` on the component */
    dataRole: string
}

/**
 * A component that renders a clickable link for navigation to another URL.
 * It is designed to be used as a child component of the `NavigationPanelSection` component.
 */
export const NavigationPanelSectionAnchor = ({ link, text, dataRole}: INavigationPanelSectionAnchorProps) => {
    return (
        <ListItem data-role={dataRole} button={ true } component="a" href={link}>
            <ListItemText primary={text} />
        </ListItem>
    );
};

export default NavigationPanelSectionAnchor;