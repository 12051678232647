import {IApplication, IApplicationPatch} from "../api";
import * as React from "react";
import {createStyles, TextField, Theme, WithStyles, withStyles} from "@material-ui/core";
import {useMemo} from "react";
import {debounce} from "lodash";

const styles = (theme: Theme) => createStyles({
  input: {
    fontSize: '0.875rem',
  }
});

/**
 * Properties for CommentsInput
 */
interface ICommentsInputProps extends WithStyles<typeof styles> {
  // The application being updated
  application: IApplication;
  // The handler for when a row is updated.
  onUpdate: (patch: IApplicationPatch) => void;
}

/**
 * Component to render a comments input. The component also prepares and dispatches a patch update.
 */
const CommentsInput: React.FunctionComponent<ICommentsInputProps> = ({
  application, onUpdate, classes
}) => {
  // handles updates to the input on a delay
  // Note: useMemo stops the debounce from being recreated every render
  const delayedOnChange = useMemo(() => debounce((value) => {
    onUpdate({
      camsisApplicationNumber: application.camsisApplicationNumber,
      comments: value === '' ? null : value
    })
  }, 500), [
    application, onUpdate
  ]);

  return <TextField
    onChange={(event: any) => delayedOnChange(event.target.value)}
    defaultValue={application.comments || ''} InputProps={{classes: {root: classes.input}}}
  />
};

export default withStyles(styles)(CommentsInput);
