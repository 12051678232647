import * as React from 'react';

import Page from '../containers/Page';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";

// the style for the table toolbar
const styles = (theme: Theme) => createStyles({
  middle: {
    margin: 'auto',
  },
  filters: {
    display: 'flex', flexDirection: 'row',
  },
});

const CaoDashboardContextPage: React.FunctionComponent<WithStyles<typeof styles>> = ({ children, classes }) => {
  return <Page>{ children }</Page>;
};

export default withStyles(styles)(CaoDashboardContextPage);
