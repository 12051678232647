// Redirect index page to summary page

import * as React from 'react';
import { Redirect } from 'react-router';

import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core/styles";

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SignInIcon from '@material-ui/icons/ExitToApp';

import {ProfileContext} from '../providers/ProfileProvider';

import Page from '../containers/Page';
import LoadingIndicator from '../components/LoadingIndicator';
import signInUrl from "../signInUrl";

const styles = (theme: Theme) => createStyles({
  card: { },
  cardContent: {
    textAlign: 'center',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
});

export const SignInNotice = withStyles(styles)(({ classes }: WithStyles<typeof styles> ) =>
  <Card className={classes.card}>
    <CardContent className={classes.cardContent}>
      <Typography variant='h6' paragraph={true}>You are not signed in.</Typography>
      <Button variant="contained" color="secondary" size="medium" component="a"
          href={signInUrl('/')} id="sign-in-button">
        <SignInIcon className={classes.buttonIcon}/>
        Sign in
      </Button>
    </CardContent>
  </Card>
);

export const IndexPage = () => {
  const profile = React.useContext(ProfileContext);

  // No profile yet
  if (!profile) {
    return <Page allowAnonymous={true}><LoadingIndicator /></Page>;
  }
  // authenticated so go to summary
  if (!profile.isAnonymous) {
    return <Redirect to='/summary' />;
  }
  // need to login notice
  return <Page allowAnonymous={true}><SignInNotice /></Page>;
};

export default IndexPage;
