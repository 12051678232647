// Handles an invitation link, retrieving query params and posting an api invitation accept.

import * as React from 'react';

import {Redirect} from "react-router";
import {
  IError, IInvitation,
  invitationAccept,
} from "../api";
import {showMessage} from "../containers/Snackbar";
import Page from "../containers/Page";
import LoadingIndicator from "../components/LoadingIndicator";

const InnerInvitationsAcceptPage = () => {
  // whether or not the invitation accept is being posted
  const [isPosting, setIsPosting] = React.useState<boolean>(true);

  React.useEffect(() => {
    const { searchParams }: URL = new URL(window.location.href);
    // get the invitation link query params
    const id = searchParams.get('id');
    const token = searchParams.get('token');

    if (id && token) {
      // post the invitation accept, handing the response
      invitationAccept(id, token)
        .then((invitation: IInvitation) => showMessage(
          `You have been granted the following role: "${invitation.roleWithContext.description}"`
        ))
        .catch((error: IError) => {
          // TODO at the moment this error message is unhelpful
          // (https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/8)
          showMessage(`Invitation failed: ${error.error.message}`)
        })
        .finally(() => setIsPosting(false));
    } else {
      showMessage('The invitation link has missing parameters: it should have "id" and "token".');
      setIsPosting(false)
    }
  }, []);

  if (isPosting) {
    // the invitation accept is still posting
    return <LoadingIndicator />
  }

  // redirect to the index page whether or not the invitation accept succeeded
  return <Redirect to='/' />
};

const InvitationsAcceptPage = () => <Page><InnerInvitationsAcceptPage/></Page>;

export default InvitationsAcceptPage;
