import * as React from 'react';
import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core/styles";
import ChipInput, { ChipRendererArgs } from 'material-ui-chip-input'

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Collapse,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import { DialogProps } from '@material-ui/core/Dialog';
import { IDescription, IDescriptionsResponse, IInvitableRole, IRoleWithContext } from "../api";
import { useGlobalContextPageState } from 'providers/GlobalContextPageContextProvider';

export interface IProps {
  /** The descriptions objects: descriptions.collegePreference is used */
  descriptions: IDescriptionsResponse | null;
  /** The invitableRoles defined in the user's profile */
  invitableRoles: IInvitableRole[] | null;
  /** Whether or not the dialog is open */
  open: boolean;
  /** Handler for the cancel action */
  onCancel: () => void;
  /** Handler for the send action */
  onSend: (recipients: string[], roleWithContext: IRoleWithContext, message?: string) => void;
  /** Properties passed to the dialog */
  DialogProps: Omit<DialogProps, 'open'>;
}

const styles = (theme: Theme) => createStyles({
  errorChipRoot: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
});

interface IInnerProps extends IProps, WithStyles<typeof styles> {}

/** validates the recipient email */
const validateRecipient = (recipient: string) => (
  // For the moment we hard-code the recipient as crsid@cam.ac.uk.
  (/^[a-z0-9]{3,8}@cam\.ac\.uk$/).test(recipient)
);

// common margin definition
const MARGIN = 'normal';

/** 
 * Object representing the invitations to be sent. Maintained in state, populated by the dialog.
 */
interface INewInvitationsState {
  /** A list of recipient's email addressses */
  recipients: string[];
  /** The id of the role the recipients are invited to */
  roleId: string;
  /** If required by the role, the college affiliation */
  collegeId: string;
  /** If required by the role, the subject interest */
  subjectId: string;
  /** An optional message to be included with each invitation */
  message: string;
}

// to initialise state
const emptyNewInvitationsState: INewInvitationsState = {
  recipients: [], roleId: '', collegeId: '', subjectId: '', message: '',
};

/**
 * A dialog box that allows the user to invite people to the application.
 */
export const NewInvitationsDialog = withStyles(styles)(({
  descriptions, invitableRoles, DialogProps, open, onCancel = () => null, onSend = () => null, classes
}: IInnerProps) => {
  const [invitations, setInvitations] = React.useState(emptyNewInvitationsState);

  // The page state: required to get the possible subjects
  const state = useGlobalContextPageState();

  // Sort the `state.possibleSubjects` and map it to a list of `IDescription` objects without
  // subjects that have options.
  const subjectsAndOptions: IDescription[] = React.useMemo(() => ((state.possibleSubjects || []).sort(
    ({ description: a }, { description: b }) => a.localeCompare(b)
  ).filter(
    (description) => !description.option
  ).map(
    ({ id, description }) => ({ id, description })
  )), [state.possibleSubjects])

  // We use useMemo to create these event handlers so that the functions don't change on each
  // render and cause needless re-renders.

  // called by <ChipInput> to add a receipient
  const addRecipient = React.useMemo(() => (recipient: string) => (
    setInvitations(prev => ({
      ...prev,
      recipients: [...prev.recipients, recipient],
    }))
  ), [setInvitations]);

  // called by <ChipInput> to remove a receipient
  const removeRecipient = React.useMemo(() => (index: number) => (
    setInvitations(prev => ({
      ...prev,
      recipients: [...prev.recipients.slice(0, index), ...prev.recipients.slice(index+1)],
    }))
  ), [setInvitations]);

  // the chip renderer for <ChipInput> - styles the chip if an error
  const chipRenderer = React.useMemo(() => ({
    value, handleDelete, className
  }: ChipRendererArgs, key: number) => (
    <Chip
      key={key}
      label={value}
      className={className}
      classes={{ root: validateRecipient(value) ? '' : classes.errorChipRoot }}
      onDelete={handleDelete}
    />
  ), [classes]);

  // handler for the send action
  const handleSend = React.useMemo(() => () => {
    const roleWithContext: IRoleWithContext = {role: {id: invitations.roleId}};
    if (invitations.collegeId) {
      roleWithContext.college = {id: invitations.collegeId}
    }
    if (invitations.subjectId) {
      roleWithContext.subject = {id: invitations.subjectId}
    }
    onSend(invitations.recipients, roleWithContext, invitations.message || undefined)
  }, [invitations, onSend]);

  // A flag indicating if all the recipients are valid and whether we have at least one of them.
  const recipientsValid = React.useMemo(() => (
    (invitations.recipients.length > 0) && invitations.recipients.reduce(
      (accumulator, recipient) => accumulator && validateRecipient(recipient), true
    )
  ), [invitations.recipients]);

  // the selected role
  const roleId = invitations.roleId;
  const selectedRole = React.useMemo(() => (
    (invitableRoles || []).find(role => role.id === roleId)
  ), [invitableRoles, roleId]);
  // show the subject select for the selected role?
  const showSubject = selectedRole && selectedRole.subjectRequired;
  // show the college select for the selected role?
  const showCollege = selectedRole && selectedRole.collegeRequired;

  // enable the send button?
  const enableSend = (
    recipientsValid
    && (invitations.roleId !== '')
    && (!showCollege || (invitations.collegeId !== ''))
    && (!showSubject || (invitations.subjectId !== ''))
  );

  // are any recipients in error?
  const recipientsError = (invitations.recipients.length > 0) && !recipientsValid;

  return (
    <Dialog
      open={open}
      onClose={() => onCancel()}
      aria-labelledby="invitation-dialog-title"
      onExited={() => setInvitations(emptyNewInvitationsState)}
      {...DialogProps}
    >
      <DialogTitle id="invitation-dialog-title">Invite people to a new role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You may invite people to a new role on this website. 
          They will be sent an email with instructions on how to accept the invitation.
          These invitations will not remove any existing roles from people.
        </DialogContentText>

        {/* the recipients input */}
        <ChipInput
          margin={MARGIN}
          blurBehavior="add"
          id="emails"
          label="Recipients"
          value={invitations.recipients}
          fullWidth
          newChipKeyCodes={[13, 32] /* Enter and space */}
          onAdd={addRecipient}
          onDelete={(recipients, index) => removeRecipient(index)}
          chipRenderer={chipRenderer}
          error={recipientsError}
          helperText={
            recipientsError ? 'Recipient email addresses must be of the form crsid@cam.ac.uk' : ''
          }
          InputProps={{
            autoFocus: true
          }}
        />

        {/* the role select */}
        <FormControl fullWidth margin={MARGIN}>
          <InputLabel htmlFor='role'>Role</InputLabel>
          <Select
            id='role'
            value={invitations.roleId}
            onChange={event => {
              // Copy value since setInvitation does not act immediately.
              const roleId = event.target.value as string;
              setInvitations(prev => ({...prev, roleId}));
            }}
          >
            {(invitableRoles || []).map(role => (
              <MenuItem key={role.id} value={role.id}>{role.description}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* the college select */}
        <Collapse in={showCollege}>
          <FormControl fullWidth margin={MARGIN}>
            <InputLabel htmlFor='college'>College</InputLabel>
            <Select
              id='college'
              value={invitations.collegeId}
              onChange={event => {
                // Copy value since setInvitations does not act immediately.
                const collegeId = event.target.value as string;
                setInvitations(state => ({...state, collegeId}));
              }}
            >
              {((descriptions && descriptions.collegePreferences) || []).map(college => (
                <MenuItem key={college.id} value={college.id}>{college.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Collapse>

        {/* the subject select */}
        <Collapse in={showSubject}>
          <FormControl fullWidth margin={MARGIN}>
            <InputLabel htmlFor='subject'>Subject</InputLabel>
            <Select
              id='subject'
              value={invitations.subjectId}
              onChange={event => {
                // Copy value since setInvitations does not act immediately.
                const subjectId = event.target.value as string;
                setInvitations(state => ({...state, subjectId}));
              }}
            >
              {subjectsAndOptions.map(subject => (
                <MenuItem key={subject.id} value={subject.id}>
                  {`${subject.description} (${subject.id})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Collapse>

        {/* the optional message */}
        <TextField
          fullWidth
          multiline
          margin={MARGIN}
          label="Message"
          helperText="Optional message to include in invitation"
          value={invitations.message}
          onChange={event => {
            // Copy value since setInvitation does not act immediately.
            const message = event.target.value as string;
            setInvitations(prev => ({...prev, message }));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button color="primary" disabled={!enableSend} onClick={handleSend}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default NewInvitationsDialog;
