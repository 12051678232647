/** fixed guids for well known annotation types */
export const ANNOTATION_TYPE_GUID_HOME = '20a42d74-c4c6-11e9-926c-cb9703adfac0';
export const ANNOTATION_TYPE_GUID_IN_CARE = '4453416a-c4c6-11e9-a67c-43e04be4cbd4';
export const ANNOTATION_TYPE_GUID_FSM = '50d6b7c8-c4c6-11e9-a09a-67f0dfe3e4e6';
export const ANNOTATION_TYPE_GUID_OAC = '7e747e5e-c4c6-11e9-b078-2be825aaabdb';
export const ANNOTATION_TYPE_GUID_IMD = '8fd7d1b4-c4c6-11e9-ad00-779da73c198a';
export const ANNOTATION_TYPE_GUID_LOW_GCSE = '9de3a1f2-c4c6-11e9-bb21-4f6dcee6b178';
export const ANNOTATION_TYPE_GUID_FEW_OXBRIDGE = 'ac208906-c4c6-11e9-91f4-c7695c012a8d';
export const ANNOTATION_TYPE_GUID_SCHOOL_TYPE = '3e402102-c4c7-11e9-baad-cf6f996d05f6';
export const ANNOTATION_TYPE_GUID_FEE_STATUS = '4e8e676c-c4c7-11e9-9c39-1f762b66db73';
export const ANNOTATION_TYPE_GUID_GENDER = 'fbf62e06-c4e2-11e9-b133-0b0c864924b7';
export const ANNOTATION_TYPE_GUID_MATURE_STATUS = '7a04a89f-2d27-4c4c-bbbb-2184bc0d5855';
export const ANNOTATION_TYPE_GUID_AFFILIATE_STATUS = 'fc45c32f-d2ca-47bb-aac8-216ff7702111'

/** Flags shown in the UI (some are one-to-one with annotations, others are calculated), along
  * with their text and colour when rendered as chips */
export type IAnnotationInfoLabelPair =
  [string, { colour: string, text: string, description: string, exportColumnNameOverride?: string, disabled?: boolean}];

/** Colours picked from the 200 (4th) row: http://www.webfreelancer.com.br/color/colors.html */
export const ANNOTATION_INFO_LABEL_ENTRIES: IAnnotationInfoLabelPair[] = [
  [ANNOTATION_TYPE_GUID_IN_CARE,
    {colour: '#ef9a9a', text: 'In Care', description: 'Spent time in local authority care'}],
  [ANNOTATION_TYPE_GUID_FSM,
    {colour: '#b39ddb', text: 'FSM', description: 'Free school meals'}],
  [ANNOTATION_TYPE_GUID_OAC,
    {colour: '#81d4fa', text: 'OAC', description: 'Output Area Socio-economic Classification 2011 flag', exportColumnNameOverride: 'Socio-economic: OAC'}],
  [ANNOTATION_TYPE_GUID_IMD,
    {colour: '#a5d6a7', text: 'IMD', description: 'Indices of Multiple Deprivation Q1-2 flag'}],
  [ANNOTATION_TYPE_GUID_LOW_GCSE,
   {colour: '#fff59d', text: 'GCSE school', description: 'Lower-performing GCSE school', exportColumnNameOverride: 'Lower-performing GCSE School'}],
  [ANNOTATION_TYPE_GUID_FEW_OXBRIDGE,
    {colour: '#ffab91', text: 'Few Cam/Ox', description: 'Few Cam/Ox offer school'}],
  [ANNOTATION_TYPE_GUID_MATURE_STATUS,
    {colour: '#bcaaa4', text: 'Mature Status', description: 'Mature student status'}],
  [ANNOTATION_TYPE_GUID_AFFILIATE_STATUS,
    {colour: '#b0b9f1', text: 'Affiliated', description: 'Affiliated status', exportColumnNameOverride: 'Affiliated Status'}],
  // these annotations are derived from other annotations/data
  ['overseas',
   {colour: '#f48fb1', text: 'Overseas', description: 'Overseas', exportColumnNameOverride: 'Fee Status (Self-declared in UCAS. Caution advised)'}],
  ['maintained',
    {colour: '#80cbc4', text: 'Maintained', description: 'Maintained Sector school'}],
  ['polar4-1',
    {colour: '#d4e157', text: 'POLAR4 Q1', description: 'Quintile 1 Low Participation Neighbourhood'}],
  ['polar4-2',
    {colour: '#9ccc65', text: 'POLAR4 Q2', description: 'Quintile 2 Low Participation Neighbourhood'}],
];
