import * as React from 'react';
import { IProfile, profileGet } from "../api";
import { sendAnalytics, sha256 } from "../utils";

/**
 * React context for the current user profile.
 */
export const ProfileContext = React.createContext<null | IProfile>(null);

/**
 * User profile provider
 */
export const ProfileProvider: React.FunctionComponent = ({children}) => {
  const [profile, setProfile] = React.useState<IProfile | null>(null);
  React.useEffect(() => {
    profileGet().then(newProfile => {
      setProfile(newProfile);
      if (newProfile?.email) {
        sha256(`${newProfile.email} ${newProfile.dateJoined}`).then((hash) => {
          // Identify user to analytics via hash of their email address (for anonymity)
          // @ts-ignore
          const GA_MEASUREMENT_ID = window['GA_MEASUREMENT_ID'];
          sendAnalytics('config', GA_MEASUREMENT_ID, {
            send_page_view: false,
            user_id: hash
          });

          // Determine user roles and inform analytics
          const userProperties: any = {};
          if (newProfile?.isSuperuser) {
            userProperties.role_su = true;
          }
          for (let i = 0; i < newProfile.groups.length; ++ i) {
            const group = newProfile.groups[i];
            if (group.name === "Central Admissions Officers") {
              userProperties.role_cao = true;
            } else if (group.name === "Admissions Tutors") {
              userProperties.role_at = true;
            } else if (group.name === "College Admissions Administrators") {
              userProperties.role_caa = true;
            } else if (group.name === "Subject Convenors") {
              userProperties.role_sc = true;
            } else if (group.name === "Directors of Studies") {
              userProperties.role_dos = true;
            } else if (group.name === "Interviewers") {
              userProperties.role_i = true;
            }
          }
          sendAnalytics('set', 'user_properties', userProperties);
        });
      }
    });
  }, []);
  return <ProfileContext.Provider value={profile}>{ children }</ProfileContext.Provider>;
};
