// src/RevokeGroupMemberships.js
//
import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useState} from 'react';
import { CSRF_TOKEN } from '../api';

export default function RevokeGroupMemberships() {
  const [openGetDialog, setOpenGetDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [getText, setGetText] = useState("Fetching group memberships...");
  const [deleteText, setDeleteText] = useState("Deleting group memberships...");

  const handleCheckGroups = () => {
    fetch("/caoDashboard/revokeGroupMemberships/", {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CSRF_TOKEN,
        },
    })
      .then((response) => response.json())
      .then((data) => handleRevokePrepare(data))
      .catch((error) => {
        // Permission (edge case, user should not see menu anyway)
        // Empty
        setGetText("An error occurred!" + error);
      })
  };

  const handleRevokeGroups = () => {

    fetch("/caoDashboard/revokeGroupMemberships/", {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': CSRF_TOKEN,
        },
    })
      .then((response) => response.json())
      .then((data) => handleRevokeCompleted(data))
      .catch((error) => {
        // Permission
        // Some items could not be deleted
        setDeleteText("An error occurred!" + error);
      });
  };

  const getGroupMemberships = (data: any) => {
    var groupMemberships = 0;
    Object.keys(data).forEach(function(key){
      groupMemberships += Object.keys(data[key]).length;
    });
    return groupMemberships
  };

  const handleRevokePrepare = (data: any) => {
    setGetText("There will be a total of " + Object.keys(data).length + " users affected by this operation. A total of " + getGroupMemberships(data) + " group memberships will be revoked.")
  };

  const handleRevokeCompleted = (data: any) => {
    setOpenGetDialog(false);
    setOpenDeleteDialog(true);
    setDeleteText("Revoked " + getGroupMemberships(data) + " group memberships from " + Object.keys(data).length + " users");
  };

  const handleClickOpen = () => {
    setOpenGetDialog(true);
    handleCheckGroups();
  };

  const handleClose = () => {
    setOpenGetDialog(false);
    setOpenDeleteDialog(false);
  };

  return (
      <Card>
        <Box p={3} pt={1}>
          <h3>Revoke Group Memberships</h3>
          <p>Revoke all group memberships for all temporary users across the following groups:</p>
          <ul>
            <li>Affiliated users and convenors for all subjects and colleges</li>
            <li>Subject Convenors</li>
            <li>Subject Convenor Inviters</li>
            <li>Admissions Tutors</li>
            <li>Admissions Tutor Inviters</li>
            <li>College Admissions Administrators</li>
            <li>Directors of Studies</li>
            <li>Directors of Studies Inviters</li>
            <li>Interviewers</li>
            <li>Interviewer Inviters</li>
          </ul>
        
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Revoke Group Memberships
          </Button>
        </Box>

        <Dialog
          open={openGetDialog}
          onClose={handleClose}
          aria-labelledby="alert-get-revoke-group-membership-dialog-title"
          aria-describedby="alert-get-revoke-group-membership-dialog-description"
        >
          <DialogTitle id="alert-get-revoke-group-membership-dialog-title">{"Are you sure you want to revoke group memberships?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-get-revoke-group-membership-dialog-description">
              This operation is not reversible. All groups for all users who are not marked as permanent in their SMI User Profile will be deleted.
              <br />
              <br />
              {getText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" id="revokeGroupMembershipCancelButton" autoFocus>
              Cancel
            </Button>
            <Button onClick={handleRevokeGroups} color="secondary" id="revokeGroupMembershipRevokeButton">
              Revoke all
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteDialog}
          onClose={handleClose}
          aria-labelledby="alert-revoke-group-membership-delete-dialog-title"
          aria-describedby="alert-revoke-group-membership-delete-dialog-description"
        >
          <DialogTitle id="alert-revoke-group-membership-delete-dialog-title">{"Group Memberships Revoked"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-revoke-group-membership-delete-dialog-description">
              {deleteText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" id="revokeGroupMembershipCancelButton">
              Done
            </Button>
          </DialogActions>
        </Dialog>

      </Card>
  );
}


