import {Chip, createStyles, Theme, withStyles} from "@material-ui/core";
import * as React from "react";

const styles = (theme: Theme) => createStyles({
  sizeSmall: {
    height: 20,
    fontSize: '85%',
    color: 'rgb(0, 0, 0, 0.66)',
  },
});

/**
 * A customisation of the MUI `<Chip>` component that makes the chip more compact if the `size`
 * property is set to `small`.
 */
// <CompactChip> is wrapped in a forwardRef component as described in
// [Caveat with refs](https://material-ui.com/guides/composition/#caveat-with-refs)
const CompactChip = React.forwardRef(
  (props:any, ref) => <Chip {...props} ref={ref} />
);


export default withStyles(styles)(CompactChip);
