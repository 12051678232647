import {createStyles, TextField, Theme, withStyles, WithStyles} from "@material-ui/core";
import {IApplication, IApplicationPatch, IAssessmentScore, IAssessmentType} from "../api";
import {
  ASSESSMENT_TYPE_GUID_AT_INTERVIEW_1, ASSESSMENT_TYPE_GUID_AT_INTERVIEW_2,
  ASSESSMENT_TYPE_GUID_COLLEGE_RANK
} from '../constants/assessmentScoreTypes';
import * as React from "react";
import { debounce, range } from 'lodash';
import {useMemo} from "react";

const styles = (theme: Theme) => createStyles({
  input: {
    fontSize: '0.875rem',
  }
});

/**
 * A context for the input - useful for passing properties
 */
interface IInterviewAssessmentScoreInputContext {
  // The application being updated
  application: IApplication;
  // The interview assessment type being updated
  interviewAssessmentType: IAssessmentType;
  // The handler for when a row is updated.
  onUpdate: (patch: IApplicationPatch) => void;
}

/**
 * Properties for InterviewAssessmentScoreInput
 */
interface IInterviewAssessmentScoreInputProps extends IInterviewAssessmentScoreInputContext, WithStyles<typeof styles> {
  // The default value of the input
  defaultValue: string | null | undefined;
}

// 1.0 to 10.0 with 0.5 precision
const VALID_SCORES_INTERVIEW = new Set(range(10, 105, 5).map(v => (v/10).toFixed(1)));
// 0.0 to 99.9 with 0.1 precision
const VALID_SCORES_AT_INTERVIEW_AND_COLLEGE_RANK = new Set(range(0, 1000).map(v => (v/10).toFixed(1)));

const validScoreValues = (typeId: string): Set<string> => {
  if ([ASSESSMENT_TYPE_GUID_AT_INTERVIEW_1,
       ASSESSMENT_TYPE_GUID_AT_INTERVIEW_2,
       ASSESSMENT_TYPE_GUID_COLLEGE_RANK].includes(typeId)) {
    return VALID_SCORES_AT_INTERVIEW_AND_COLLEGE_RANK;
  }
  return VALID_SCORES_INTERVIEW;
};

// validates an interview assessment score
const validateScore = (typeId: string, score: string): boolean | string => {
  if (score === '') {
    return '';
  }
  // split on decimal point making blanks in to zeros
  const parts = score.split('.').map((part: string) => part === '' ? '0' : part);
  if (parts.length > 3) {
    // x.y.z is not valid
    return false;
  }
  // trim leading zeros
  parts[0] = parts[0].replace(/^0+([0-9])/,'$1')
  if (parts.length === 1) {
    // no decimal so add a zero
    parts.push('0');
  } else {
    // trim trailing zeros in the decimal
    parts[1] = parts[1].replace(/([0-9])0+$/,'$1');
  }
  // check rejoined parts are in the array of valid scores for this assessment score type
  const validatedScore = parts.join('.')
  if (!validScoreValues(typeId).has(validatedScore)) {
    return false;
  }
  return validatedScore;
};

// validates an interview assessment score
const validScoreExamples = (typeId: string): string => {
  let validList = Array.from(validScoreValues(typeId));
  return validList.slice(0,3).join(', ') + ', .. ' + validList.slice(-1)[0];
};

// prepares and dispatches a patch update
const updateScore = (context: IInterviewAssessmentScoreInputContext, score: string) => {
  const {application, interviewAssessmentType, onUpdate} = context;
  const scoreOrNull = score === '' ? null : `${score}`;
  const assessmentScores: IAssessmentScore[] = application.assessmentScores.filter(
    assessmentScore => assessmentScore.typeId === interviewAssessmentType.id
  );
  if (assessmentScores.length > 0) {
    assessmentScores[0].score = scoreOrNull
  } else {
    assessmentScores.push({
      typeId: interviewAssessmentType.id,
      typeDescription: interviewAssessmentType.description,
      permissions: {update: true},
      score: scoreOrNull
    })
  }
  onUpdate({camsisApplicationNumber: application.camsisApplicationNumber, assessmentScores})
};

/**
 * Component to render an interview assessment score input. The component validates the score
 * and, if valid, prepares and dispatches a patch update.
 */
const InterviewAssessmentScoreInput: React.FunctionComponent<IInterviewAssessmentScoreInputProps> = ({
  interviewAssessmentType, application, onUpdate, defaultValue, classes
}) => {
  // whether or not the input is in an error state
  const [error, setError] = React.useState<string | null>(null);

  // handles updates to the input on a delay
  // Note: useMemo stops the debounce from being recreated every render
  const delayedOnChange = useMemo(() => debounce((value) => {
      const validatedScore = validateScore(interviewAssessmentType.id, value.trim());
      if (validatedScore === false) {
        setError(`Must be one of (${validScoreExamples(interviewAssessmentType.id)})`);
      } else {
        setError(null);
        updateScore({application, interviewAssessmentType, onUpdate}, validatedScore as string)
      }
    }, 300), [
    application,
    interviewAssessmentType,
    onUpdate
  ]);

  return <TextField
    onChange={(event: any) => delayedOnChange(event.target.value)}
    error={!!error} helperText={error} defaultValue={defaultValue}
    InputProps={{classes: {root: classes.input}}}
  />
};

export default withStyles(styles)(InterviewAssessmentScoreInput);
