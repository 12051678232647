import * as React from 'react';
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export interface INavigationPanelSectionLinkProps {
    /** The route to the new location */
    to: string
    /** The link's text */
    text: string
    /** Whether of not the link is in selected mode */
    selected: boolean
    /** Renders as `data-role` on the component */
    dataRole: string
}

/**
 * A component that renders a clickable link for internal navigation to another page in the application.
 * Wraps the `Link` component from the `react-router-dom` library.
 * It is designed to be used as a child component of the `NavigationPanelSection` component.
 */
export const NavigationPanelSectionLink = ({ to, selected, dataRole, text}: INavigationPanelSectionLinkProps) => {
    return (
        <ListItem button={ true } component={ Link } to={to} selected={selected} 
            data-role={dataRole} data-to={to} data-selected={selected.toString()}
        >
            <ListItemText primary={text} />
        </ListItem>
    );
};

export default NavigationPanelSectionLink;
