import * as React from 'react';

import SummaryTable from '../components/SummaryTable';
import { useSummaryTable } from '../hooks/useSummaryTable';
import { IGlobalFilters, SecondAxis } from '../api';

export interface ApplicationSummaryTableProps {
  /** Second axis to break statistics down by. */
  secondAxis: SecondAxis,

  /** Query used to filter applications. */
  query?: IGlobalFilters,
}

/**
 * Uses the applicationCounts and assessmentScoreHistogram endpoints to provide a summary of
 * applications returned by some query. The applications are grouped by some second axis which must
 * be provided as the secondAxis prop.
 */
export const ApplicationSummaryTable = ({ secondAxis, query = {} }: ApplicationSummaryTableProps) => {
  const { isLoading, rows } = useSummaryTable(secondAxis, query);

  // For the description, we fall back to rendering the secondAxis value if we don't recognise it.
  const secondAxisDescription = SECOND_AXIS_DESCRIPTIONS.get(secondAxis) || secondAxis;

  return (
    <SummaryTable
      isLoading={isLoading}
      rows={rows}
      secondAxisDescription={secondAxisDescription}
    />
  );
}

export default ApplicationSummaryTable;

// Map from second axis to description.
const SECOND_AXIS_DESCRIPTIONS = new Map<SecondAxis, string>([
  ['collegePreference', 'College'],
  ['subject', 'Subject'],
  ['latestDecision', 'Decision'],
  ['admitYear', 'Admit year'],
]);
