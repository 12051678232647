/**
 * Custom University of Cambridge material UI theme
 */

// Since the ordering of properties has some meaning to users editing this file even if they are of
// no interest to the code, we disable the alphabetical sort check.
//
// tslint:disable:object-literal-sort-keys

import { createTheme } from '@material-ui/core/styles';

export const drawerWidth = 30 * 8;

const theme = createTheme({
  palette: {
    primary: {
      light: '#5fa7ff',
      main: '#0b79d0',
      dark: '#004e9e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffbc45',
      main: '#ff8b00',
      dark: '#c55c00',
      contrastText: '#000',
    },
  }
});

export default theme;
