// Render appropriate page content based on current route.

import * as React from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'

// The various pages we know how to render.
import ApplicationsPage from "../pages/ApplicationsPage";
import IndexPage from "../pages/IndexPage";
import SummaryPage from "../pages/SummaryPage";
import ChartsPage from "../pages/ChartsPage";
import InvitationsPage from "../pages/InvitationsPage";
import InvitationsAcceptPage from "../pages/InvitationsAcceptPage";
import CaoDashboardPage from "../pages/CaoDashboardPage";
import { sendAnalytics } from "../utils";

const InternalRoutes: React.SFC = () => {
  const location = useLocation();

  React.useEffect(() => {
    const pathname = location.pathname;
    sendAnalytics('set', 'page_path', pathname);
    sendAnalytics('set', 'page_title', pathname.charAt(1).toUpperCase() + pathname.slice(2));
    sendAnalytics('event', 'page_view');
  }, [location]);

  return (
    <Switch>
      <Route exact={true} path="/applications" component={ApplicationsPage} />
      <Route exact={true} path="/summary" component={SummaryPage} />
      <Route exact={true} path="/charts" component={ChartsPage} />
      <Route exact={true} path="/invitations/accept" component={InvitationsAcceptPage} />
      <Route exact={true} path="/invitations" component={InvitationsPage} />
      <Route exact={true} path="/caoDashboard" component={CaoDashboardPage} />

      {/* A generic "catch-all" route for if we have no specific page to render.
       Renders the generic index page. */}
      <Route component={IndexPage} />
    </Switch>
  );
};

export const Routes: React.SFC = () => {
  return (
    <BrowserRouter>
      <InternalRoutes/>
    </BrowserRouter>
  );
};

export default Routes;
