import * as React from 'react';

import VisibilitySensor from 'react-visibility-sensor';
import LoadingIndicator from 'components/LoadingIndicator';

export interface FetchMoreDataProps {
  /**
   * Whether there is further data to load.
   */
  hasMore: boolean,
  /**
   * Whether data is currently being fetched
   */
  isFetching: boolean | undefined,
  /**
   * A event triggered when more data should be loaded (when the sensor becomes visible)
   */
  onFetchMore: () => void,
}

/**
 * A specialisation of the VisibilitySensor component intended for use at the end of a list/table
 * to trigger the loading of ac further page of data (if it has any).
 */
export const FetchMoreData = ({ hasMore, isFetching, onFetchMore }: FetchMoreDataProps) => (
  hasMore
  ?
  <VisibilitySensor partialVisibility={true} active={!isFetching} onChange={(isVisible:boolean) => {
    if (isVisible) {
      onFetchMore()
    }
  }}>
    <LoadingIndicator/>
  </VisibilitySensor>
  :
  null
);

export default FetchMoreData;
