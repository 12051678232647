/**
 * Context for a map of applications keyed on camsisApplicationNumber. This context is intended as
 * an app wide cache of applications. To update the map, dispatch() is used
 */
import * as React from 'react';
import {IApplication} from "../api";

/** The action to be used with dispatch() */
export interface IApplicationsContextAction {
  type: 'SET_APPLICATIONS';
  payload: IApplication[];
}

/** A custom type defining a map of applications keyed on an id (camsisApplicationNumber) */
export type ApplicationsState = ({[index: string]: IApplication});

export const ApplicationsStateContext = React.createContext<ApplicationsState>({});
export const ApplicationsDispatchContext = React.createContext(
  (action: IApplicationsContextAction) => {}
);

/** reducer to perform any state changes */
const reducer = (state: ApplicationsState, action: IApplicationsContextAction): ApplicationsState => {
  const newState = {...state};
  switch(action.type) {
    case 'SET_APPLICATIONS':
      // set each application into the state map
      action.payload.forEach((application: IApplication) => (
        newState[application.camsisApplicationNumber] = application
      ));
      return newState;
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const ApplicationsContextProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {});

  return <ApplicationsStateContext.Provider value={state}>
    <ApplicationsDispatchContext.Provider value={dispatch}>{
      children
    }</ApplicationsDispatchContext.Provider>
  </ApplicationsStateContext.Provider>;
};

export const useApplicationsState = () => React.useContext(ApplicationsStateContext);
export const useApplicationsDispatch = () => React.useContext(ApplicationsDispatchContext);
