import {IApplication, IApplicationPatch, IFullDescription} from "../api";
import * as React from "react";
import {createStyles, MenuItem, Select, Theme, WithStyles, withStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  fontSize: {
    fontSize: '0.875rem',
  },
  width: {
    width: '100%'
  },
});

/**
 * Properties for DecisionStatusSelect
 */
interface IDecisionStatusSelectProps extends WithStyles<typeof styles> {
  // The list of decision types
  decisionTypes: IFullDescription[] | null;
  // The application being updated
  application: IApplication;
  // The handler for when a row is updated.
  onUpdate: (patch: IApplicationPatch) => void;
}

/**
 * Component to render a status (decision) input. The component also prepares and dispatches a
 * patch update.
 */
const DecisionStatusSelect: React.FunctionComponent<IDecisionStatusSelectProps> = ({
  application, decisionTypes, onUpdate, classes
}) => {

  const onChange = (event: any) => {
    onUpdate({
      camsisApplicationNumber: application.camsisApplicationNumber,
      latestDecisionTypeId: `${event.target.value}`,
    })
  };

  return <Select value={application.latestDecisionTypeId || ''} onChange={onChange}
                 classes={{root: classes.fontSize}} className={classes.width}
  >
    {
      (decisionTypes || []).map(decisionType => (
        <MenuItem className={classes.fontSize} key={decisionType.id} value={decisionType.id}>
          {decisionType.description}
        </MenuItem>
      ))
    }
  </Select>
};

export default withStyles(styles)(DecisionStatusSelect);
