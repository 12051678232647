import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';

import Routes from './containers/Routes';

import theme from './theme';
import Snackbar from "./containers/Snackbar";
import {ApplicationsContextProvider} from "./providers/ApplicationsContextProvider";
import {DescriptionsContextProvider} from "./providers/DescriptionsContextProvider";
import {GlobalFiltersProvider} from "./providers/GlobalFiltersProvider";
import {ProfileProvider} from './providers/ProfileProvider';

import {GlobalContextPageContextProvider} from "./providers/GlobalContextPageContextProvider";

// Render application content
ReactDOM.render(
  <MuiThemeProvider theme={ theme }>
    <DescriptionsContextProvider>
      <ProfileProvider>
        <GlobalFiltersProvider>
          <GlobalContextPageContextProvider>
            <ApplicationsContextProvider>
              <Routes />
              { /* Global notification snackbar */ }
              <Snackbar />
            </ApplicationsContextProvider>
          </GlobalContextPageContextProvider>
        </GlobalFiltersProvider>
      </ProfileProvider>
    </DescriptionsContextProvider>
  </MuiThemeProvider>,
  document.getElementById('app')
);
