// Dashboard for CAO operations.
import * as React from 'react';
                                                                                         
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';                                                          
import RevokeGroupMemberships from '../components/RevokeGroupMemberships';
import Grid from '@material-ui/core/Grid';

import CaoDashboardContextPage from "../containers/CaoDashboardContextPage";
import DeleteProvisionalDecisions from "../components/DeleteProvisionalDecisions";

const CaoDashboardPage = () => (
  <CaoDashboardContextPage>
    <h3>CAO Dashboard</h3>
    <Box mb={3}>
      <Divider />
    </Box>
    <Grid container spacing={3} direction='row'>
      <Grid item md={12} lg={6}><RevokeGroupMemberships /></Grid>
      <Grid item md={12} lg={6}><DeleteProvisionalDecisions /></Grid>
    </Grid>
  </CaoDashboardContextPage>
);

export default CaoDashboardPage;
