import * as React from 'react';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow, { TableRowProps } from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import LoadingIndicator from '../components/LoadingIndicator';

/**
 * The raw data which forms a row in the summary table. Primarily it is a description of the second
 * axis, an id for the second axis and various application counts.
 */
export interface SummaryTableRow {
  /** Machine-readable unique identifier for the second axis value. */
  secondAxisId: string;

  /** Human-friendly description of second axis value. */
  secondAxisDescription: string;

  /** Total number of applications for this second axis group. */
  count: number;

  /** Number of "HOME" applicants. */
  homeCount: number;

  /** Number of applicants with overseas fee status. */
  overseasCount: number;

  /** Number of applicants in POLAR4 quintile 1. */
  lpnQ1Count: number;

  /** Number of applicants in POLAR4 quintile 2. */
  lpnQ2Count: number;

  /** Number of applications from schools which are not "independent" or "other". */
  maintainedCount: number;

  /** Number of applicants recorded as "female". */
  femaleCount: number;

  /** Count with Output Area Socio-economic Classification 2011 flag set. */
  oacCount: number;

  /** Count taking free school meals. */
  fsmCount: number;

  /** Count with Indices of Multiple Deprivation Q1-2 flag set. */
  imdCount: number;
};

export interface SummaryTableProps {
  /**
   * An array of summary table statistics to display in the table. If undefined, no table is
   * rendered.
   */
  rows?: SummaryTableRow[];

  /**
   * A description of the second axis. Used to populate the heading of the first column.
   */
  secondAxisDescription?: string;

  /** Show a loading indicator for the table. */
  isLoading?: boolean;
}

// Which columns can we sort and in what directions?
type SortDirection = 'desc' | 'asc';
type SortColumn =
  'secondAxisDescription' | 'count' | 'lpnQ1Count' | 'lpnQ2Count' | 'overseasCount' |
  'maintainedRatio' | 'genderRatio' | 'fsmCount' | 'imdCount' | 'oacCount';

// Record of sorting state.
interface SortState {
  direction: SortDirection;
  column: SortColumn;
}

/**
 * SummaryTable renders a set of application summary statistics as returned by useSummaryTable().
 */
export const SummaryTable = (
  { rows, secondAxisDescription = '', isLoading = false }: SummaryTableProps
) => {
  const classes = useStyles();

  // Current sort state
  const [sortState, setSortState] = React.useState<SortState>({
    direction: 'asc', column: 'secondAxisDescription'
  });

  // Get rows sorted by current sort direction and column. Avoid re-sorting unless we need to by
  // means of useMemo().
  const sortedRows = React.useMemo(() => sortRows(rows || [], sortState), [rows, sortState]);

  // Compute the total row by summing all the displayed rows.
  const totalRow = sumTableRows(sortedRows);

  // Construct an a11y label.
  const tableLabel = `${secondAxisDescription} summary`;

  // Helper function to render table header cells.
  const renderHeader = (column: SortColumn, content: React.ReactNode) => (
    <TableSortLabel
      active={sortState.column === column}
      direction={sortState.direction}
      onClick={() => setSortState(
        ({ direction, column: prevColumn }) => ({
          // Only toggle direction if column selected was the same as the previous selection.
          direction: (column !== prevColumn) ? direction : ((direction === 'asc') ? 'desc' : 'asc'),
          column,
        })
      )}
    >
      { content }
    </TableSortLabel>
  );

  // For cells spanning an entire row, it is useful to have the number of columns in the table
  // available as a constant.
  const columnCount = 10;

  return (
    <Table className={`summary-table ${classes.table}`} padding="none" aria-label={tableLabel}>
      <TableHead>
        <TableRow>
          <TableCell id={`${secondAxisDescription}-column`} className="summary-table-second-axis-description">
            { renderHeader('secondAxisDescription', secondAxisDescription) }
          </TableCell>
          <TableCell id="count-column" align="right" className="summary-table-count">
            {
              renderHeader(
                'count',
                <Tooltip title="Number of applicants"><span>Count</span></Tooltip>
              )
            }
          </TableCell>
          <TableCell id="ofs-column" align="right" className="summary-table-ofs-ratio">
            {
              renderHeader(
                'maintainedRatio',
                <Tooltip title={<>Proportion of <strong>home</strong> applicants from maintained school</>}>
                  <span>OfS</span>
                </Tooltip>
              )
            }
          </TableCell>
          <TableCell id="fem-column" align="right" className="summary-table-gender-ratio">
            {
              renderHeader(
                'genderRatio',
                <Tooltip title="Female applicants"><span>Fem</span></Tooltip>
              )
            }
          </TableCell>
          <TableCell id="LPN1-column" align="right" className="summary-table-lpn-q1">
            {
              renderHeader(
                'lpnQ1Count',
                <Tooltip title="Applicants in a quintile 1 POLAR4 Low Participation Neighbourhood"><span>POLAR4&nbsp;Q1</span></Tooltip>
              )
            }
          </TableCell>
          <TableCell id="LPN2-column" align="right" className="summary-table-lpn-q2">
            {
              renderHeader(
                'lpnQ2Count',
                <Tooltip title="Applicants in a quintile 2 POLAR4 Low Participation Neighbourhood"><span>POLAR4&nbsp;Q2</span></Tooltip>
              )
            }
          </TableCell>
          <TableCell id="overseas-column" align="right" className="summary-table-overseas">
            {
              renderHeader(
                'overseasCount',
                <Tooltip title="Overseas fee status applications"><span>Fee Status (Self-declared in UCAS. Caution advised)</span></Tooltip>
              )
            }
          </TableCell>
          <TableCell id="oac-column" align="right" className="summary-table-oac">
            {
              renderHeader(
                'oacCount',
                <Tooltip title="Applicants with Output Area Socio-economic Classification 2011 flag"><span>Socio-economic: OAC</span></Tooltip>
              )
            }
          </TableCell>
          <TableCell id="fsm-column" align="right" className="summary-table-overseas">
            {
              renderHeader(
                'fsmCount',
                <Tooltip title="Applicants taking Free School Meals"><span>FSM</span></Tooltip>
              )
            }
          </TableCell>
          <TableCell id="imd-column" align="right" className="summary-table-overseas">
            {
              renderHeader(
                'imdCount',
                <Tooltip title="Applicants with Indices of Multiple Deprivation Q1-2 flag"><span>IMD</span></Tooltip>
              )
            }
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          /* Show a loading indicator if requested. */
          isLoading &&
            <TableRow>
              <TableCell colSpan={columnCount} className="summary-table-loading-indicator">
                <LoadingIndicator />
              </TableCell>
            </TableRow>
        }

        {
          /* No data condition is when rows is defined but is empty. */
          rows && (rows.length === 0) &&
            <TableRow>
              <TableCell colSpan={columnCount}>
                <Box display="flex" justifyContent="center" color="text.hint" p={2}>
                  <Typography variant="body1">No data</Typography>
                </Box>
              </TableCell>
            </TableRow>
        }

        {
          /* The actual data rows themselves. */
          sortedRows.map(
            (row, index) => <Row row={row} key={index} />
          )
        }
      </TableBody>

      <TableFooter>
        {
          /* Only render the total row if we have more than one row to show. */
          (sortedRows.length > 1) && <Row row={totalRow} TableCellProps={{ variant: 'head' }} />
        }
      </TableFooter>
    </Table>
  );
}

export default SummaryTable;

// Helper component to render a table row.
interface RenderRowProps {
  row: SummaryTableRow;
  TableRowProps?: TableRowProps;
  TableCellProps?: TableCellProps;
}

const Row = ({ row, TableRowProps, TableCellProps }: RenderRowProps) => {
  const formatPercentage = (count: number) => (
    (row.count === 0) ? '\u2013' : `${Math.round(100 * count / row.count)}%`
  );

  const formatCount = (count: number) => count.toLocaleString();

  return (
    <TableRow {...TableRowProps}>
      <TableCell className="summary-table-second-axis-description" {...TableCellProps}>
        { row.secondAxisDescription }
      </TableCell>
      <TableCell align="right" className="summary-table-count" {...TableCellProps}>
        { row.count.toLocaleString() }
      </TableCell>
      <TableCell align="right" className="summary-table-ofs-ratio" {...TableCellProps}>
        { formatMaintainedRatio(row) }
      </TableCell>
      <TableCell align="right" className="summary-table-gender-ratio" {...TableCellProps}>
        { formatPercentage(row.femaleCount) }
      </TableCell>
      <TableCell align="right" className="summary-table-lpn-q1" {...TableCellProps}>
        { formatCount(row.lpnQ1Count) }
      </TableCell>
      <TableCell align="right" className="summary-table-lpn-q2" {...TableCellProps}>
        { formatCount(row.lpnQ2Count) }
      </TableCell>
      <TableCell align="right" className="summary-table-overseas" {...TableCellProps}>
        { formatCount(row.overseasCount) }
      </TableCell>
      <TableCell align="right" className="summary-table-oac" {...TableCellProps}>
        { formatCount(row.oacCount) }
      </TableCell>
      <TableCell align="right" className="summary-table-fsm" {...TableCellProps}>
        { formatCount(row.fsmCount) }
      </TableCell>
      <TableCell align="right" className="summary-table-imd" {...TableCellProps}>
        { formatCount(row.imdCount) }
      </TableCell>
    </TableRow>
  );
}

// Hook for custom CSS styles.
const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    // To avoid table columns re-sizing depending on the data we use the table header widths to set
    // the rest of the table layout size.
    tableLayout: 'fixed',

    // The strategy here is to set all header cells to a fixed width and then the "fixed" table
    // layout will allocate the available space in proportion to the widths but keep the specified
    // widths as a minimum size.

    '& thead tr th': {
      width: theme.spacing(8),
    },

    '& thead tr th.summary-table-second-axis-description': {
      width: theme.spacing(24),
    },

    '& td, & th': {
      padding: `${theme.spacing(1)}px 0`,
    },

    '& td:first-child, & th:first-child': {
      paddingLeft: theme.spacing(2),
    },

    '& td:last-child, & th:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));

// Format the OfS statistic from a summary row.
export const formatMaintainedRatio = ({ maintainedCount, homeCount }: SummaryTableRow) => (
  (homeCount === 0) ? '\u2013' : `${Math.round(100 * maintainedCount / homeCount)}%`
);

// Compare two SummaryTableRow-s based on a column. Returns -ve number if a is before b, 0 if a ===
// b and +ve number if a is after b.
const compareRow = (a: SummaryTableRow, b: SummaryTableRow, sortColumn: SortColumn) => {
  // Our comparison function differs based on the selected sort column.
  if(sortColumn === 'count') {
    return a.count - b.count;
  } else if(sortColumn === 'lpnQ1Count') {
    return a.lpnQ1Count - b.lpnQ1Count;
  } else if(sortColumn === 'lpnQ2Count') {
    return a.lpnQ2Count - b.lpnQ2Count;
  } else if(sortColumn === 'overseasCount') {
    return a.overseasCount - b.overseasCount;
  } else if(sortColumn === 'imdCount') {
    return a.imdCount - b.imdCount;
  } else if(sortColumn === 'fsmCount') {
    return a.fsmCount - b.fsmCount;
  } else if(sortColumn === 'oacCount') {
    return a.oacCount - b.oacCount;
  } else if(sortColumn === 'secondAxisDescription') {
    // The description is a string so we should compare it using the current locale.
    return `${a.secondAxisDescription}`.localeCompare(`${b.secondAxisDescription}`);
  } else if(sortColumn === 'maintainedRatio') {
    // Note, this can be NaN if homeCount is zero for a or b.
    const cmp = (a.maintainedCount / a.homeCount) - (b.maintainedCount / b.homeCount);
    return isNaN(cmp) ? 0 : cmp;
  } else if(sortColumn === 'genderRatio') {
    // Note, this can be NaN if count is zero for a or b.
    const cmp = (a.femaleCount / a.count) - (b.femaleCount / b.count);
    return isNaN(cmp) ? 0 : cmp;
  }

  // Unrecognised sort column key; we have no ordering.
  return 0;
}

// Sort an array of SummaryTableRow-s using a stable sort. The sort is not stable if any two rows
// have identical secondAxisId values.
const sortRows = (rows: SummaryTableRow[], sortState: SortState) => (
  // Use broadcasting here to copy the array since sort() works in-place.
  [...rows].sort((a, b) => (
    // We stabilise the sort by using the secondAxisId as a tie-breaker. The "||" operator is used
    // to "chain" the orderings so that an ordering of 0 (items are equal) is passed to the
    // tie-breaker.
    compareRow(a, b, sortState.column) * ((sortState.direction === 'desc') ? -1 : 1)
    || `${a.secondAxisId}`.localeCompare(`${b.secondAxisId}`)
  ))
)

// Sum an array of SummaryTableRow-s into a grand total row.
const sumTableRows = (rows: SummaryTableRow[]): SummaryTableRow => rows.reduce(
  (sumRow, row) => ({
    secondAxisId: sumRow.secondAxisId,
    secondAxisDescription: sumRow.secondAxisDescription,
    count: sumRow.count + row.count,
    homeCount: sumRow.homeCount + row.homeCount,
    overseasCount: sumRow.overseasCount + row.overseasCount,
    lpnQ1Count: sumRow.lpnQ1Count + row.lpnQ1Count,
    lpnQ2Count: sumRow.lpnQ2Count + row.lpnQ2Count,
    maintainedCount: sumRow.maintainedCount + row.maintainedCount,
    femaleCount: sumRow.femaleCount + row.femaleCount,
    oacCount: sumRow.oacCount + row.oacCount,
    fsmCount: sumRow.fsmCount + row.fsmCount,
    imdCount: sumRow.imdCount + row.imdCount,
  }),
  {
    secondAxisId: 'sum',
    secondAxisDescription: 'Total',
    count: 0,
    homeCount: 0,
    overseasCount: 0,
    lpnQ1Count: 0,
    lpnQ2Count: 0,
    maintainedCount: 0,
    femaleCount: 0,
    oacCount: 0,
    fsmCount: 0,
    imdCount: 0,
  }
);
