import * as React from 'react';

import Box from '@material-ui/core/Box';

import ComparisonHistogram from '../components/ComparisonHistogram';
import { Chart, ChartTitle, ChartContent } from '../components/Chart';
import { IAssessmentScoreHistogramResponse } from 'api';

export interface AssessmentScoreComparisonChartProps {
  title: React.ReactNode;
  isLoading?: boolean;
  specificResponse?: IAssessmentScoreHistogramResponse | null;
  generalResponse?: IAssessmentScoreHistogramResponse | null;
};

const useShownCount = (response: IAssessmentScoreHistogramResponse | null) => React.useMemo(() => {
  if(response === null) { return 0; }

  return (
    response.valueBucketCounts
    .filter(({ interval: { low, high } }) => (low !== null) && (high !== null))
    .reduce((accumulator, { count }) => accumulator + count, 0)
  );
}, [response]);

/**
 * A Chart component which shows a histogram of assessment scores for a specific and general view.
 * Wraps ComparisonHistogram.
 */
export const AssessmentScoreComparisonChart: React.FunctionComponent<AssessmentScoreComparisonChartProps> = (
  { title, isLoading = false, specificResponse = null, generalResponse = null }
) => {
  // Compute the total count of *shown* values. Only do this if the response changes.
  const totalShownCountSpecific = useShownCount(specificResponse);
  const totalShownCountGeneral = useShownCount(generalResponse);

  const noSpecificData = !isLoading &&
    ((specificResponse === null) || (totalShownCountSpecific === 0));
  const noGeneralData = !isLoading &&
    ((generalResponse === null) || (totalShownCountGeneral === 0));

  return (
    <Chart>
      <ChartTitle>{ title }</ChartTitle>
      <ChartContent
        showLoading={isLoading}
        showNoDataState={noSpecificData || noGeneralData}
      >
        <Box p={2} flexGrow={1}>
          <ComparisonHistogram
            specificResponse={specificResponse}
            generalResponse={generalResponse}
          />
        </Box>
      </ChartContent>
    </Chart>
  )
};

export default AssessmentScoreComparisonChart;
