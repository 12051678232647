import * as React from 'react';

import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core/styles";

import {
  Box, IconButton, Link,
  Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel,
  Tooltip, Typography
} from "@material-ui/core";
import {
  IAnnotation,
  IApplication, IApplicationPatch, IApplicationPermissions,
  IApplicationQuery,
  IAssessmentScore, IAssessmentType,
  IDescriptionsResponse,
  IPoolOutcomeCreate,
  IDescription,
} from "../api";
import { sendAnalytics } from "../utils";
import {
  ASSESSMENT_TYPE_GUID_POLAR4,
  ASSESSMENT_TYPE_INTERVIEW_1, ASSESSMENT_TYPE_INTERVIEW_2,
  ASSESSMENT_TYPE_INTERVIEW_3, ASSESSMENT_TYPE_INTERVIEW_4,
  ASSESSMENT_TYPE_INTERVIEW_5, ASSESSMENT_TYPE_INTERVIEW_6,
  ASSESSMENT_TYPE_INTERVIEW_AVERAGE,
} from '../constants/assessmentScoreTypes';
import {
  ANNOTATION_INFO_LABEL_ENTRIES,
  ANNOTATION_TYPE_GUID_FEE_STATUS, ANNOTATION_TYPE_GUID_SCHOOL_TYPE, ANNOTATION_TYPE_GUID_GENDER,
} from '../constants/annotationTypes';
import {ReactElement} from "react";
import CompactChip from "./CompactChip";
import { camelCase, kebabCase, fromPairs } from 'lodash';
import InterviewAssessmentScoreInput from "./InterviewAssessmentScoreInput";
import DecisionStatusSelect from "./DecisionStatusSelect";
import CommentsInput from "./CommentsInput";

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PoolOutcomeSelect from './PoolOutcomeSelect';
import PoolOutcomeAdmitYearInput from './PoolOutcomeAdmitYearInput';
import PoolOutcomeSubjectSelect from './PoolOutcomeSubjectSelect';


/**
 * Encapsulates the sorted state of the table
 */
interface ITableOrdering {
  // Which column is sorted. If undefined, the table is unsorted.
  columnKey?: string;
  // The direction of the sort if sorted,
  direction?: 'asc' | 'desc';
}

const ANNOTATION_INFO_LABELS = fromPairs(ANNOTATION_INFO_LABEL_ENTRIES);

const styles = (theme: Theme) => createStyles({
  table: {
    tableLayout: 'fixed',
    '& thead tr th': {
      width: theme.spacing(8),
    },
    '& th.th-name, td.td-name': {
      paddingLeft: 0,
    },
    '& th.th-actions': {
      width: 50,
    },
    '& th.th-status': {
      width: 115,
    },
  },
  // A class to be applied to info chips that defines their margin and distinguishing colour.
  infoChips: fromPairs(ANNOTATION_INFO_LABEL_ENTRIES.map(
    (entry: any) => [`& .info-${entry[0]}`, {
      marginRight: 2,
      marginBottom: 2,
      backgroundColor: entry[1].colour}
    ]
  ))
});

// Properties common to both ApplicationsPageTable and ApplicationRow
interface ICommonProps {
  // A list of which columns render (select from a list provided by `generateAllColumns()`)
  columns: IColumnDefinition[];
  // The handler for view application detail event
  onViewApplication?: (application: IApplication) => void;
}

// The main component's properties
interface IProps extends ICommonProps, WithStyles<typeof styles> {
  // A page of applications to display.
  applications: IApplication[];
  // The handler for an ordering event
  onOrderChange: (ordering: IApplicationQuery) => void;
}

/**
 * Base definition for the mapping of the application data to a table column.
 */
export interface IBaseColumnDefinition {
  /** a unique key */
  key: string;
  /** the column's title */
  title: string;
  /** how to render a table cell for a particular application */
  render: (application: IApplication) => any;
}

/**
 * Defines a sub-column (used only when rendering a CSV download).
 *
 * The `render()` function will only be called to render the column data when generating a CSV
 * download, and will never be called to render the column data on-screen (as sub-columns are
 * never visible on-screen).
 */
export type ISubColumnDefinition = IBaseColumnDefinition;

/**
 * Defines the mapping of the application data to a table column.
 *
 * The `render()` function will be called to render the column data both on-screen and when
 * generating a CSV download, unless a `renderForDownload()` function is provided in which case
 * the latter will be used when generating a CSV download.
 */
export interface IColumnDefinition extends IBaseColumnDefinition {
  /** optional secondary text as a description */
  secondaryText?: string;
  /** the term to be used when ordering, if the column is sortable */
  ordering?: string;
  /** any additional query params to be applied when sorting */
  orderingQuery?: IApplicationQuery;
  /**
   * Defined when rendering a field for a CSV download is different from render().
   * If an array is returned then the field will be expanded into multiple columns in the CSV
   * download, and each entry in the array should be a column definition with just the `key`,
   * `title` and `render` properties.
  */
  renderForDownload?: ((application: IApplication) => string) | ISubColumnDefinition[];
  /** visibility of the column, default is 'both' */
  visibility?: 'download' | 'ui' | 'both';
}

/**
 * Returns 'overseas' if a 'fee status' annotation with value of 'Overseas' is found.
 */
const createOverseasAnnotation = (application: IApplication) => (
  application.annotations.find((annotation: IAnnotation) => (
    annotation.type.id === ANNOTATION_TYPE_GUID_FEE_STATUS && annotation.value === 'Overseas'
  )) ? 'overseas' : null
);

/**
 * Returns 'maintained' if a 'school type' annotation whose values are neither
 * 'Independent School' nor 'Other' is found.
 */
const createOffaSchoolAnnotation = (application: IApplication) => (
  application.annotations.find((annotation: IAnnotation) => (
    annotation.type.id === ANNOTATION_TYPE_GUID_SCHOOL_TYPE &&
    annotation.value !== 'Independent School' && annotation.value !== 'Other'
  )) ? 'maintained' : null
);

/**
 * Returns `polar4-${score}` if a 'polar4' assessment whose value is `${score}.00` is found.
 */
const createPolar4Annotation = (score: string) => (application: IApplication) => (
  application.assessmentScores.find((assessmentScore: IAssessmentScore) => (
    assessmentScore.typeId === ASSESSMENT_TYPE_GUID_POLAR4 &&
    assessmentScore.score === `${score}.00`
  )) ? `polar4-${score}` : null
);

/**
 * Selects a subset of the application's annotations to display in the info column. Additional
 * 'overseas', 'maintained', 'POLAR4 (1)', & 'POLAR4 (2)' annotations are possibly derived from
 * other annotations/data.
 */
const filterAnnotations = (application: IApplication): IAnnotation[] => {

  const annotations: IAnnotation[] = [...application.annotations];

  [
    createOverseasAnnotation,
    createOffaSchoolAnnotation,
    createPolar4Annotation('1'),
    createPolar4Annotation('2')
  ].forEach(create => {
    const annotation = create(application);
    if (annotation) {
      annotations.push({type: {id: annotation, description: ''}, value: 'Y'})
    }
  });

  return annotations.filter(
    (annotation: IAnnotation) => ANNOTATION_INFO_LABELS[annotation.type.id] && annotation.value === 'Y'
  )
};

/**
 * A custom renderer for the 'Info' column, Renders a subset of the application's annotations into
 * a set of coloured chips.
 */
const renderInfoLabels = (application: IApplication): ReactElement => {
  return <>{
    filterAnnotations(application).map((annotation: IAnnotation) => (
      <Tooltip key={annotation.type.id} placement="top"
        title={ANNOTATION_INFO_LABELS[annotation.type.id].description}
      >
        <CompactChip size="small" className={`info-${annotation.type.id}`}
          label={ANNOTATION_INFO_LABELS[annotation.type.id].text}
        />
      </Tooltip>
    ))
  }</>
};

/**
 * A custom renderer for the gender column
 */
const renderGender = (application: IApplication) => {
  const annotation = application.annotations.find(
    annotation => annotation.type.id === ANNOTATION_TYPE_GUID_GENDER
  );
  if (annotation) {
    return annotation.value === 'F' ? 'Female' : (annotation.value === 'M' ? 'Male' : '')
  }
};

/**
 * A custom renderer for the school type column
 */
 const renderSchoolType = (application: IApplication) => {
  const annotation = application.annotations.find(
    annotation => annotation.type.id === ANNOTATION_TYPE_GUID_SCHOOL_TYPE
  );
  if (annotation) {
    return annotation.value;
  }
};

/**
 * A custom download renderer for the applicant files link column
 */
const renderApplicantFilesForDownload = (application: IApplication) => {
  // If there is no external resources, render a blank column.
  if (!application.externalResources || (application.externalResources.length === 0)) {
    return '';
  }
  // Otherwise, render the URL.
  return application.externalResources[0].externalUrl;
};

/**
 * A custom renderer for the applicant files link column
 */
const renderApplicantFiles = (application: IApplication) => {
  const externalUrl = renderApplicantFilesForDownload(application)
  // Render either a blank column or a link to the applicant files from the first external
  // resource. Use target="_blank" to make sure this opens in a new tab. We also set rel="..."
  // appropriately to avoid potential session hijack via window.opener.
  // See: https://mathiasbynens.github.io/rel-noopener/
  return (
    externalUrl
    ?
    <Link
      href={application.externalResources[0].externalUrl}
      target="_blank" rel="noopener noreferrer"
      onClick={() => {
        sendAnalytics('event', 'view_applicant_folder', {
          view_applicant_folder_value: true
        });
      }}
    >
      View
    </Link>
    :
    externalUrl
  )
};

const renderBirthdate = (application: IApplication) => {
  if (application.birthdate === null) {
    return null
  } else {
    const date = new Date(application.birthdate);
    return new Intl.DateTimeFormat('en-GB').format(date)
  }
}


// defines the columns that can be statically defined
const STATIC_COLUMNS:IColumnDefinition[] = [
  {
    key: 'name',
    title: 'Name',
    secondaryText: "Candidate's given name",
    render: (application: IApplication) => application.candidate.displayName,
    ordering: 'candidateLastName,candidateForenames',
    visibility: 'ui',
  },{
    key: 'forenames',
    title: 'Forenames',
    secondaryText: "Candidate's forenames",
    render: (application: IApplication) => application.candidate.forenames,
    ordering: 'candidateForenames',
    visibility: 'download',
  },{
    key: 'lastname',
    title: 'Last Name',
    secondaryText: "Candidate's last name",
    render: (application: IApplication) => application.candidate.lastName,
    ordering: 'candidateLastName',
    visibility: 'download',
  },{
    key: 'subject',
    title: 'Subject',
    secondaryText: "Subject being applied for",
    render: (application: IApplication) => application.subjectDescription,
    ordering: 'subjectDescription',
  },{
    key: 'academic-plan',
    title: 'Academic Plan',
    secondaryText: "Academic plan code of the course being applied for",
    render: (application: IApplication) => application.subject,
    ordering: 'subject',
  },{
    key: 'tags',
    title: 'Flags',
    secondaryText: 'Contextual information on application or applicant',
    render: renderInfoLabels,
    // array of sub-columns that the column is expanded into when downloading as CSV
    renderForDownload: ANNOTATION_INFO_LABEL_ENTRIES.map((entry): ISubColumnDefinition => ({
      key: entry[0],
      title: entry[1].exportColumnNameOverride || entry[1].text,
      // regenerate annotations for each sub-column and select the value of the required
      // annotation, which is inefficient but fast enough give the number of annotations and
      // avoids a large code refactor.
      render: (application: IApplication) => filterAnnotations(application).filter(
        annotation => entry[1].text === ANNOTATION_INFO_LABELS[annotation.type.id].text
      ).length > 0 ? 'Y' : ''
    }))
  },{
    key: 'college',
    title: 'College',
    secondaryText: "Candidate's college preference",
    render: (application: IApplication) => application.collegePreferenceDescription,
    ordering: 'collegePreferenceDescription',
  },{
    key: 'gender',
    title: 'Gender',
    secondaryText: "Candidate's gender",
    render: renderGender,
  },{
    key: 'ucas',
    title: 'UCAS Personal ID',
    secondaryText: "Candidate's UCAS personal ID (PID)",
    render: (application: IApplication) => application.candidate.ucasPersonalId,
    ordering: 'ucas_personal_id'
  },{
    key: 'usn',
    title: 'USN',
    secondaryText: "Candidate's USN",
    render: (application: IApplication) => application.candidate.camsisUsn,
    ordering: 'usn',
  },{
    key: 'application',
    title: 'CamSIS Number',
    secondaryText: "Application's CamSIS number",
    render: (application: IApplication) => application.camsisApplicationNumber,
    ordering: 'camsisApplicationNumber',
  },{
    key: 'camsis-status',
    title: 'CamSIS Status',
    secondaryText: "Application's CamSIS status",
    render: (application: IApplication) => application.camsisStatusDescription,
    ordering: 'camsisStatusDescription',
  },{
    key: 'subject-options',
    title: 'Subject options',
    secondaryText: 'Subject-specific options',
    render: (application: IApplication) => application.subjectOptions.join(', '),
  },{
    key: 'applicant-files',
    title: 'Applicant Files',
    secondaryText: 'Link to digital applicant files when present',
    render: renderApplicantFiles,
    renderForDownload: renderApplicantFilesForDownload,
  },{
    key: 'pool-type',
    title: 'Pool Type',
    secondaryText: 'If the application has been pooled, specify which pool it is in',
    // We special-case the 'NONE' pool type to be an empty cell.
    render: (application: IApplication) => (
      (application.poolType === 'NONE') ? '': application.poolTypeDescription || ''
    ),
  },{
    key: 'pool-status',
    title: 'Pooled Status',
    secondaryText: 'More information about the current pooling status of the application',
    // We special-case the 'NONE' pool status to be an empty cell.
    render: (application: IApplication) => (
      (application.poolStatus === 'NONE') ? '': application.poolStatusDescription || ''
    ),
  },{
    key: 'entry-year',
    title: 'Entry Year',
    secondaryText: "The applicant's entry year",
    render: (application: IApplication) => application.admitYear,
    ordering: 'admit_year',
  },{
    key: 'interview-college-1',
    title: 'Pool Interview College 1',
    secondaryText: "College interviewing via Winter Pool",
    render: (application: IApplication) => application.latestPoolOutcome?.interviewCollege1Description,
  },{
    key: 'interview-college-2',
    title: 'Pool Interview College 2',
    secondaryText: "College interviewing via Winter Pool",
    render: (application: IApplication) => application.latestPoolOutcome?.interviewCollege2Description,
  },{
    key: 'predicted-grades',
    title: 'Predicted Grades',
    secondaryText: "The applicant's predicted grades",
    render: (application: IApplication) => application.predictedGrades
  }, {
    key: 'gcse-school-name',
    title: 'GCSE School Name',
    secondaryText: 'The name of the school where the applicant took their GCSEs, as declared in the SAQ',
    render: (application: IApplication) => application.gcseSchoolName,
    ordering: 'gcse_school_name',
  }, {
    key: 'gcse-school-postcode',
    title: 'GCSE School Postcode',
    secondaryText: 'The postcode of the school where the applicant took their GCSEs, as declared in the SAQ',
    render: (application: IApplication) => application.gcseSchoolPostcode,
    ordering: 'gcse_school_postcode',
  }, {
    key: 'school-name',
    title: 'School Name',
    secondaryText: (
      'Name of the applicant\'s school. Derived from school code of "last educational ' +
      'establishment" (apply centre) according to UCAS.'
    ),
    render: (application: IApplication) => application.schoolName,
    ordering: 'school_name',
  }, {
    key: 'school-type',
    title: 'School Type',
    secondaryText: (
      'Type of the applicant\'s school. Derived from school type according to UCAS or CAO ' +
      'override value where different.'
    ),
    render: renderSchoolType,
  }, {
    key: 'home-postcode',
    title: 'Home Postcode',
    secondaryText: 'The applicant\'s home postcode',
    render: (application: IApplication) => application.homePostcode,
  }, {
  key: 'birthdate',
    title: 'Birthdate',
    secondaryText: 'The applicant\'s date of birth',
    render: renderBirthdate,
  }, {
    key: 'country-of-domicile',
    title: 'Country of Domicile',
    secondaryText: 'The applicant\'s declared country of permanent residence',
    render: (application: IApplication) => application.countryOfDomicile,
    ordering: 'country_of_domicile'
  }, {
    key: 'collegeDecision',
      title: 'College decision (CamSIS)',
      secondaryText: 'The College\'s decision recorded in CamSIS',
      render: (application: IApplication) => application.collegeDecisionDescription,
      ordering: 'college_decision'
  }
];

/** decides if the user can create/update a score */
const isScoreEditable = (permissions: IApplicationPermissions, score: (IAssessmentScore | undefined)): boolean => (
  // if the score doesn't exist - can the user create interview assessment scores?
  (score === undefined && (permissions.createAssessmentScores || permissions.createInterviewAssessmentScores)) ||
  // if the score exists - can the user update that score?
  (!!score && score.permissions.update)
);

/**
 * A dictionary of pool outcome descriptions indexed by their id.
 */
const POOL_OUTCOME_CHOICES = {
  'ACCEPT': 'Accepted',
  'REJECT': 'Rejected',
  'HOLD': 'On Hold',
  'OFFER': 'Offer',
  'INTERVIEW': 'Interview',
}

/**
 * Convert POOL_OUTCOME_CHOICES to a list of `IDescription` objects for the select component
 */
const POOL_OUTCOME_OPTIONS = Object.entries(POOL_OUTCOME_CHOICES).map(entry => (
  {id: entry[0], description: entry[1]}
))

/**
 * A custom download renderer for the pool outcome column
 */
const renderPoolOutcomeForDownload = (application: IApplication) => {
  return (
    application.latestPoolOutcome && application.latestPoolOutcome.status &&
    POOL_OUTCOME_CHOICES[application.latestPoolOutcome.status]
  ) || ''
};

/**
 * A custom download renderer for the college change column
 */
const renderCollegeChangeForDownload = (application: IApplication) => {
  return (
    application.latestPoolOutcome && application.latestPoolOutcome.collegePreferenceDescription
  ) || ''
};

/**
 * A custom download renderer for the subject change column
 */
const renderSubjectChangeForDownload = (application: IApplication) => {
  return (
    application.latestPoolOutcome && application.latestPoolOutcome.subjectDescription
  ) || ''
};

/**
 * A custom download renderer for the entry year change column
 */
const renderEntryYearChangeForDownload = (application: IApplication) => {
  return (
    application.latestPoolOutcome && String(application.latestPoolOutcome.admitYear)
  ) || ''
};

export const ASSESSMENT_TYPE_INTERVIEWS = new Set([
  ASSESSMENT_TYPE_INTERVIEW_1, ASSESSMENT_TYPE_INTERVIEW_2,
  ASSESSMENT_TYPE_INTERVIEW_3, ASSESSMENT_TYPE_INTERVIEW_4,
  ASSESSMENT_TYPE_INTERVIEW_5, ASSESSMENT_TYPE_INTERVIEW_6,
]);

/**
 * Render the average interview score. If the score comprises one or more zero scores, the score is
 * highlighted and given an explanatory tooltip.
 */
 const renderAverageInterviewScore = (application: IApplication, score?: string | null) => {
  const zero_score = application.assessmentScores.find(
    score => ASSESSMENT_TYPE_INTERVIEWS.has(score.typeId) && score.score === '0.00'
  )
  if (!zero_score) {
    return score
  }
  return <Tooltip title='This average comprises at least one zero interview score.'>
    <Box component="span" bgcolor='#f48fb1' paddingLeft={0.5} paddingRight={0.5}>
      {score}
    </Box>
  </Tooltip>
};

/**
 * Generates a list of all possible columns definitions to the consumer. Some column definitions
 * require `IDescriptionsResponse` data, `subjectsAndOptions` (the subject filter list) and the
 * `onUpdate` handler to complete their definition.
 */
export const generateAllColumns = (
  descriptions: IDescriptionsResponse | null,
  onUpdate: (patch: IApplicationPatch) => void,
  onPoolOutcomeCreate: (poolOutcome: IPoolOutcomeCreate) => void,
  subjectsAndOptions: IDescription[],
) => {

  // A renderer for the decision status column
  const renderStatus = (application: IApplication) => {
    // .. return just the decision if the user can't update the decision
    if (!application.permissions.createDecisions) {
      return application.latestDecision && application.latestDecision.typeDescription
    }
    return <DecisionStatusSelect
      onUpdate={onUpdate} application={application}
      decisionTypes={descriptions && descriptions.decisionTypes}
    />
  };

  // A renderer for the comments column
  const renderComment = (application: IApplication) => {
    // .. return just the comments if the user can't update the comments
    if (!application.permissions.updateComments) {
      return application.comments
    }
    return <CommentsInput application={application} onUpdate={onUpdate}/>
  };

  // A custom renderer for the pool outcome column
  const renderPoolOutcome = (application: IApplication) => {
    // .. return just the pool outcome if the user can't update the pool outcome
    if (!application.permissions.createPoolOutcomes) {
      return renderPoolOutcomeForDownload(application)
    }
    return <PoolOutcomeSelect
      options={POOL_OUTCOME_OPTIONS}
      application={application}
      onPoolOutcomeCreate={onPoolOutcomeCreate}
      field="status"
      dataRole="pool-outcome-selection"
    />
  };

  // A custom renderer for the college change column
  const renderCollegeChange = (application: IApplication) => {
    // .. return just the college change if the user can't update the pool outcome
    if (!application.permissions.createPoolOutcomes) {
      return renderCollegeChangeForDownload(application)
    }
    return <PoolOutcomeSelect
      options={descriptions && descriptions.collegePreferences}
      application={application}
      onPoolOutcomeCreate={onPoolOutcomeCreate}
      field="collegePreference"
      dataRole="college-change-selection"
    />
  };

  // A custom renderer for the subject change column
  const renderSubjectChange = (application: IApplication) => {
    // .. return just the subject change if the user can't update the pool outcome
    if (!application.permissions.createPoolOutcomes) {
      return renderSubjectChangeForDownload(application)
    }
    return <PoolOutcomeSubjectSelect
      subjectsAndOptions={subjectsAndOptions}
      application={application}
      onPoolOutcomeCreate={onPoolOutcomeCreate}
      dataRole="subject-change-selection"
    />
  };

  // A custom renderer for the entry year change column
  const renderEntryYearChange = (application: IApplication) => {

    const defaultValue = renderEntryYearChangeForDownload(application);

    // .. return just the entry year change if the user can't update the pool outcome
    if (!application.permissions.createPoolOutcomes) {
      return defaultValue
    }
    return <PoolOutcomeAdmitYearInput
      application={application}
      onPoolOutcomeCreate={onPoolOutcomeCreate}
      defaultValue={defaultValue}
    />
  };

  const columns = [
    ...STATIC_COLUMNS,
    {
      key: 'provisionalCollegeDecision',
      title: 'Provisional College Decision',
      secondaryText: 'Most recent provisional decision made on application',
      render: renderStatus,
      renderForDownload: (application: IApplication) => (
        (application.latestDecision && application.latestDecision.typeDescription) || ''
      ),
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestDecisionDescription',
    },{
      key: 'comments',
      title: 'Comments',
      secondaryText: 'Free-form textual comment',
      render: renderComment,
      renderForDownload: (application: IApplication) => application.comments || '',
      ordering: 'comments',
    },{
      key: 'pool-outcome',
      title: 'Pool outcome',
      secondaryText: 'The status of an application at the completion of the pooling process',
      render: renderPoolOutcome,
      renderForDownload: renderPoolOutcomeForDownload,
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestPoolOutcomeStatus',
    },{
      key: 'college-change',
      title: 'College Change',
      secondaryText: "The college offered to the candidate.",
      render: renderCollegeChange,
      renderForDownload: renderCollegeChangeForDownload,
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestPoolOutcomeCollegePreference',
    },{
      key: 'subject-change',
      title: 'Subject Change',
      secondaryText: "The updated subject of the candidate.",
      render: renderSubjectChange,
      renderForDownload: renderSubjectChangeForDownload,
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestPoolOutcomeSubject',
    },{
      key: 'entry-year-change',
      title: 'Entry Year Change',
      secondaryText: "The applicant's updated entry year.",
      render: renderEntryYearChange,
      renderForDownload: renderEntryYearChangeForDownload,
      // disabled due to https://gitlab.developers.cam.ac.uk/uis/devops/digital-admissions/pools/smi/-/issues/325
      // ordering: 'latestPoolOutcomeAdmitYear',
    }];

  // The renderer for the assessment score columns
  const renderAssessmentScore = (interviewAssessmentType: IAssessmentType) => (application: IApplication) => {
    const assessmentScore = application.assessmentScores.find(
      assessmentScore => assessmentScore.typeId === interviewAssessmentType.id
    );
    const score = (assessmentScore && assessmentScore.score);
    if (interviewAssessmentType.id === ASSESSMENT_TYPE_INTERVIEW_AVERAGE) {
      // if it's an interview average, render that
      return renderAverageInterviewScore(application, score)
    }
    else if (!interviewAssessmentType.isInterview) {
      // else if the type isn't an interview just render the score
      return score
    }
    // format the interview score with 1dp
    const interviewScore = score && score.substr(0, score.length - 1);
    // if the user doesn't have edit permission then render the interview score
    if (!isScoreEditable(application.permissions, assessmentScore)) {
      return interviewScore
    }
    return <InterviewAssessmentScoreInput
      interviewAssessmentType={interviewAssessmentType}
      application={application}
      onUpdate={onUpdate}
      defaultValue={interviewScore}
    />
  };

  // defines the assessment columns.
  const assessmentTypes = (descriptions && descriptions.assessmentTypes.sort(
    (a, b) => (a.description.localeCompare(b.description))
  )) || [];

  columns.push(...assessmentTypes.map((assessmentType, index) => ({
      // hack to differentiate between 'Number of A GCSEs' and 'Number of A* GCSEs'
      key: kebabCase(assessmentType.description.replace('*', 'x')),
      title: assessmentType.description,
      secondaryText: assessmentType.longDescription,
      render: renderAssessmentScore(assessmentType),
      ordering: 'rankingAssessmentScore',
      orderingQuery: {rankingAssessmentType: assessmentType.id},
      renderForDownload: (application: IApplication) => {
        const assessmentScore = application.assessmentScores.find(
          assessmentScore => assessmentScore.typeId === assessmentType.id
        );
        return (assessmentScore && assessmentScore.score) || ''
      }
  })));
  return columns;
}

/**
 * Component to render alist of `IApplication` objects as a table. The component doesn't decide
 * itself which of the large number of fields to render as columns. Instead it exports
 * `generateAllColumns()` which returns a list of `IColumnDefinition` objects, each defining a
 * column. The consumer can then select which columns to using the `columns` property
 */
const ApplicationsPageTable: React.FunctionComponent<IProps> = ({
  applications, onOrderChange,
  onViewApplication = () => null, columns, classes
}) => {

  // Defines the current table ordering
  const [tableOrdering, setTableOrdering] = React.useState<ITableOrdering>({
    columnKey: 'name',
    direction: 'desc'
  });

  // Updates the tableOrdering state and dispatch a requested for reordered data
  const handleTableSort = React.useMemo(() => (column: IColumnDefinition) => {
    if (tableOrdering.columnKey === column.key) {
      if (tableOrdering.direction === 'desc') {
        setTableOrdering({...tableOrdering, direction: 'asc'});
        // Ordering string can contain multiple comma-separated fields, so
        // reverse the direction of all of them
        const ordering = column.ordering || "";
        onOrderChange({
          ordering: '-' + ordering.replace(",", ",-"),
          ...column.orderingQuery}
        );
      } else if (tableOrdering.direction === 'asc') {
        setTableOrdering({});
        onOrderChange({ordering: ''});
      }
    } else {
      setTableOrdering({columnKey: column.key, direction: 'desc'});
      onOrderChange({ordering: column.ordering, ...column.orderingQuery});
    }
  }, [onOrderChange, tableOrdering, setTableOrdering]);

  const dataProps = {'data-role': 'applicationTable'};

  return <>
    <Table stickyHeader className={`${classes.infoChips} ${classes.table}`} size="small" {...dataProps} >
      <TableHead>
        <TableRow>
          <TableCell className='th-actions' padding='default' id="full-view-column"  key={"full-view"}>
            Full Details
          </TableCell>
          {
            columns.map(column => {
              const dataProps={'data-role': `${camelCase(column.key)}Header`};
              return <TableCell
                id={`${kebabCase(column.title)}-column`}
                className={`th-${column.key}`}
                key={column.key}
                {...dataProps}
              >{
                column.ordering
                  ?
                  <TableSortLabel
                    active={tableOrdering.columnKey === column.key}
                    direction={tableOrdering.direction}
                    onClick={() => handleTableSort(column)}
                  >
                    {column.title}
                  </TableSortLabel>
                  :
                  column.title
              }</TableCell>
            })
          }
        </TableRow>
      </TableHead>
      <TableBody>
      {
        applications.length === 0
          ?
          <TableRow>
            <TableCell colSpan={columns.length + 1}>
              <Box
                display="flex"
                justifyContent="center" color="text.hint" p={2}
              >
                <Typography variant="body1">No data</Typography>
              </Box>
            </TableCell>
          </TableRow>
          :
          applications.map((application) => <MemoisedApplicationRow
            key={application.camsisApplicationNumber}
            application={application}
            columns={columns}
            onViewApplication={onViewApplication}
          />)
      }
      </TableBody>
    </Table>
  </>;
};

// The properties for the ApplicationRow component
interface IApplicationRowProps extends ICommonProps {
  // The application for the row to display.
  application: IApplication;
}

// A component rendering an `IApplication` as a row in a table.
const ApplicationRow: React.FunctionComponent<IApplicationRowProps> = ({
  application, columns, onViewApplication = () => null
}) => {
  const dataProps = {
    'data-role': 'application',
    'data-camsis-application-number': application.camsisApplicationNumber
  };
  return <TableRow {...dataProps}>
    <TableCell padding='default'>
      <Tooltip title="Full Application Details" aria-label="view-source">
        <IconButton id="view-source" onClick={() => onViewApplication(application)}>
          <DescriptionOutlinedIcon
            aria-controls="application-source-dialog"
            aria-haspopup="true"
          />
        </IconButton>
      </Tooltip>
    </TableCell>
    {
      columns.map((column: any) => {
        const dataProps={'data-role': column.key};
        return <TableCell aria-labelledby={`${kebabCase(column.title)}-column`} className={`td-${column.key}`} key={camelCase(column.key)} {...dataProps} >
          {column.render(application)}
        </TableCell>
      })
    }
  </TableRow>
};

// We memoise ApplicationRow to prevent all row re-rendering when we append more application rows
// to the table. Note the do re-render all rows when new columns are selected.
const MemoisedApplicationRow = React.memo(ApplicationRow, (prevProps, nextProps) => {
  const prevColumns = prevProps.columns.map(column => column.key).join('');
  const nextColumns = nextProps.columns.map(column => column.key).join('');
  // compare the applications and the column selections
  return prevColumns === nextColumns && prevProps.application === nextProps.application;
});

export default withStyles(styles)(ApplicationsPageTable);
