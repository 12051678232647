import * as React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

/**
 * Content of the side navigation drawer.
 */
export const NavigationPanel: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={ classes.root }>{children}</div>
  )
};

export default NavigationPanel;
