import { Box, Button, CardActions, DialogContent, DialogContentText } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";
import { apiFetch, CAO_DASHBOARD_BASE } from "../api";

export default function DeleteProvisionalDecisions() {
  const [showDecisionModal, setShowDecisionModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const deleteDecisions = () => {
    apiFetch(CAO_DASHBOARD_BASE + "/deleteProvisionalDecisions/", { method: "DELETE" })
      .then(() => {
        setResultMessage("All Provisional College Decisions have been deleted.");
      })
      .catch((error) => {
        const detail = error?.error;
        const detailMessage = detail || "no detail provided";
        setResultMessage("Something went wrong: " + detailMessage);
      });
  };

  const handleDeleteDecisions = () => {
    setShowDecisionModal(false);
    deleteDecisions();
    setShowResultModal(true);
  };

  const DecisionModal = () => {
    return (
      <>
        <DialogTitle id="alert-get-delete-provisional-college-dialog-title">
          Are you sure you want to delete all "Provisional College Decision" data?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-get-delete-provisional-college-dialog-description">
            This operation is not reversible.
            <br />
            All "Provisional College Decision" data will be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDecisionModal(false)}
            color="primary"
            id="revokeGroupMembershipCancelButton"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteDecisions()}
            color="secondary"
            id="revokeGroupMembershipRevokeButton"
          >
            Delete all
          </Button>
        </DialogActions>
      </>
    );
  };

  const ResultModal = () => {
    return (
      <>
        <DialogTitle id="alert-get-delete-provisional-college-result-dialog-title">
          "Provisional College Decision" data deleted
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-get-delete-provisional-college-result-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResultModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Card>
      <Box p={3} pt={1}>
        <CardContent>
          <h3>Delete "Provisional College Decision" data</h3>
          <p>This action deletes all data from the "Provisional College Decision" column.</p>
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="primary" onClick={() => setShowDecisionModal(true)}>
            Delete all
          </Button>
        </CardActions>
      </Box>

      <Dialog
        open={showDecisionModal}
        onClose={() => setShowDecisionModal(false)}
        aria-labelledby="alert-get-delete-provisional-college-dialog-title"
        aria-describedby="alert-get-delete-provisional-college-dialog-description"
      >
        <DecisionModal />
      </Dialog>

      <Dialog
        open={showResultModal}
        onClose={() => setShowResultModal(false)}
        aria-labelledby="alert-get-delete-provisional-college-result-dialog-title"
        aria-describedby="alert-get-delete-provisional-college-result-dialog-description"
      >
        <ResultModal />
      </Dialog>
    </Card>
  );
}
