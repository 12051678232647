import * as React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { Bar, BarSvgProps } from '@nivo/bar';
import { Theme } from '@nivo/core';
import { AutoSizer } from 'react-virtualized';

/**
 * An auto-sizing variant of nivo's Bar component which is themed to fit in with the rest of the
 * application.
 *
 * We cannot use ResponsiveBar since that has a number of bugs in its implementation. For example.
 * it will quite often grow without bound when placed in a flex box.
 */
export const AutoSizedBarChart: React.FunctionComponent<BarSvgProps> = (
  { axisBottom, axisLeft, margin, ...BarProps }
) => {
  const theme = useTheme();
  const nivoTheme = useNivoTheme();

  return <AutoSizer>{({ width, height }) => (
    <Bar
      width={width}
      height={height}
      animate={false}
      theme={nivoTheme}
      margin={{
        top: theme.spacing(1),
        bottom: theme.spacing(6),
        left: theme.spacing(7),
        right: theme.spacing(17),
        ...margin,
      }}
      legends={[
        {
          anchor: 'bottom-right',
          dataFrom: 'keys',
          direction: 'column',
          itemDirection: 'right-to-left',
          itemHeight: theme.spacing(2),
          itemWidth: theme.spacing(15),
          itemsSpacing: theme.spacing(1),
          justify: true,
          symbolSize: theme.spacing(2),
          translateX: theme.spacing(17),
        },
      ]}
      axisBottom={{
        legendPosition: 'middle',
        legendOffset: theme.spacing(5),
        ...axisBottom
      }}
      axisLeft={{
        legendPosition: 'middle',
        legendOffset: -theme.spacing(6),
        ...axisLeft
      }}
      enableLabel={false}
      {...BarProps}
    />
  )}</AutoSizer>
};

export default AutoSizedBarChart;

/** Customised nivo theme for charts. */
export const useNivoTheme = (): Theme => {
  const theme = useTheme();

  const captionText = {...theme.typography.caption, fill: theme.palette.text.secondary};
  const body2Text = {...theme.typography.body2, fill: theme.palette.text.primary};

  return {
    axis: {
      ticks: { text: captionText },
      legend: { text: captionText },
    },
    labels: { text: captionText },
    legends: { text: body2Text },
    dots: { text: body2Text },
    tooltip: { container: body2Text },
  };
};
