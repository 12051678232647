import {IApplication, IPoolOutcomeCreate, IDescription} from "../api";
import * as React from "react";
import {createStyles, MenuItem, Select, Theme, WithStyles, withStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  fontSize: {
    fontSize: '0.875rem',
  },
  width: {
    width: '100%'
  },
});

/**
 * Properties for PoolOutcomeSelect
 */
interface IPoolOutcomeSelectProps extends WithStyles<typeof styles> {
  /** The list of `IDescription` objects to use in select options */
  options: IDescription[] | null;
  /** The target application */
  application: IApplication;
  /** Which `IPoolOutcomeCreate` field this component should target */
  field: ('status' | 'collegePreference' | 'subject')
  /** The handler for when a pool outcome field value is selected */
  onPoolOutcomeCreate: (poolOutcome: IPoolOutcomeCreate) => void;
  /** To allow better external visbility, if set, will add a 'data-role' property to the input */
  dataRole?: string;
}

/**
 * Component to render a input select for a pool outcome field. When a selection is made the
 * component dispatches a pool outcome creation event updating the targeted field.
 */
const PoolOutcomeSelect: React.FunctionComponent<IPoolOutcomeSelectProps> = ({
  options, application, onPoolOutcomeCreate, field, dataRole, classes
}) => {

  const inputProps:any = {};
  if (dataRole) {
    inputProps['data-role'] = dataRole
  }

  // handler for select changes events - dispatches an `IPoolOutcomeCreate` populated by
  // `latestPoolOutcome` and the selected value.
  const onChange = (event: any) => {
    const poolOutcomeCreate: any = {
      status: null,
      ...application.latestPoolOutcome || {},
      applicationId: application.camsisApplicationNumber,
    };
    poolOutcomeCreate[field] = event.target.value;
    onPoolOutcomeCreate(poolOutcomeCreate);
  };

  const value = application.latestPoolOutcome && application.latestPoolOutcome[field];

  return (
    <Select 
      inputProps={inputProps} value={value || ''} onChange={onChange}
      classes={{root: classes.fontSize}} className={classes.width}
    >{
      (options || []).map(options => (
        <MenuItem className={classes.fontSize} key={options.id} value={options.id}>
          {options.description}
        </MenuItem>
      ))
    }</Select>
  )
};

export default withStyles(styles)(PoolOutcomeSelect);
