import * as React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useSessionStorage} from 'react-use';

import GlobalContextPage from '../containers/GlobalContextPage';
import { useGlobalFiltersState } from '../providers/GlobalFiltersProvider';
import AssessmentScoreChart from '../components/AssessmentScoreChart';
import AssessmentScoreComparisonChart from '../components/AssessmentScoreComparisonChart';
import {
  IAssessmentScoreHistogramQuery,
  IDescriptionsResponse
} from '../api';
import { useAssessmentScoreHistogram } from '../apiHooks';
import { sendAnalytics } from "../utils";
import {DescriptionsConsumer} from "../providers/DescriptionsContextProvider";

// A key used to store the user's preferred ranking assessment type in sessionStorage so that it
// persists while navigating the application.
export const RANKING_ASSESSMENT_TYPE_KEY = 'chartsPageRankingAssessmentType';

// The component's properties
interface IProps {
  // descriptions data retrieved from endpoint
  descriptions: IDescriptionsResponse | null;
}

export const InnerChartsPage: React.FunctionComponent<IProps> = ({descriptions}) => {
  const globalQuery = useGlobalFiltersState();

  const excludedAssessmentScoreTypes = ['c732a5c4-3a47-4ea2-a17d-88b44b5da5fe']
  // Get a list of assessment types and their descriptions.
  const assessmentScoreTypes = (descriptions && descriptions.assessmentTypes.filter(d => !excludedAssessmentScoreTypes.includes(d.id))) || [];


  // Management of specific and general histogram responses.
  const [specificResponse, fetchSpecificResponse] = useAssessmentScoreHistogram();
  const [generalResponse, fetchGeneralResponse] = useAssessmentScoreHistogram();

  // State which records the specific query we are using which matches the general query.
  const [specificQuery, setSpecificQuery] = React.useState<IAssessmentScoreHistogramQuery | null>(null);

  // We store the ranking assessment type in session storage so that it persists within the
  // current browsing session and will be restored if the user navigates away from the charts page
  // and returns.
  const [rankingAssessmentType, setRankingAssessmentType] = useSessionStorage<null | string>(
    RANKING_ASSESSMENT_TYPE_KEY, null
  );

  // When the assessment score types constants are loaded, set the ranking assessment type if it is
  // currently unset. Similarly, if the ranking assessment type is set but doesn't appear in
  // assessmentScoreTypes, unset is.
  React.useEffect(() => {
    if(!rankingAssessmentType && (assessmentScoreTypes.length > 0)) {
      setRankingAssessmentType(assessmentScoreTypes[0].id);
    }

    if(rankingAssessmentType && (assessmentScoreTypes.length > 0)) {
      if(assessmentScoreTypes.findIndex(t => t.id === rankingAssessmentType) === -1) {
        setRankingAssessmentType(null);
      }
    }
  }, [assessmentScoreTypes, rankingAssessmentType, setRankingAssessmentType]);

  // When the global query or ranking assessment score changes, kick off a request for the
  // *generic* query. We do this first because we'll use the bucket bounds from the generic query
  // to render the specific histogram as well.
  React.useEffect(() => {
    // Reset the specific query.
    setSpecificQuery(null);

    // If we don't have a global query or a ranking assessment type, that's all.
    if(!globalQuery || !rankingAssessmentType) { return; }

    // Get a general query for all colleges using a handy pattern to remove a property from an
    // object without mutating it.
    const { collegePreferenceId, ...generalQuery } = globalQuery;

    // Start fetching the general response.
    fetchGeneralResponse({ ...generalQuery, rankingAssessmentType });
  }, [globalQuery, rankingAssessmentType, fetchGeneralResponse, setSpecificQuery]);

  // When we have a response from the general query, update the specific query and kick of a
  // request.
  React.useEffect(() => {
    if(!generalResponse.isLoading && !generalResponse.error && generalResponse.response) {
      const specificQuery = {
        // Ensure that the ranking assessment type matches the general query.
        ...generalResponse.query,

        // Bring in the full global query.
        ...globalQuery,

        // And use the same histogram buckets as the general response.
        lowBounds: generalResponse.response.valueBucketLowBounds.join(','),
      };
      setSpecificQuery(specificQuery);
      fetchSpecificResponse(specificQuery);
    }
  }, [generalResponse, globalQuery, fetchSpecificResponse, setSpecificQuery]);

  return (
    <GlobalContextPage>
      <Box pb={2}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={8} lg={6}>
            <Paper>
              <Box p={2}>
                <FormControl fullWidth>
                  <InputLabel id="ranking-score" htmlFor="charts-primary-axis">Ranking score</InputLabel>
                  <Select
                    value={rankingAssessmentType || ''}
                    inputProps={{id: 'charts-primary-axis'}}
                    onChange={event => {
                      const matchedAssessmentScoreTypes = (
                        assessmentScoreTypes.filter(item => item.id === event.target.value)
                      );
                      if (matchedAssessmentScoreTypes.length > 0) {
                        sendAnalytics('event', 'change_chart_ranking_score', {
                          change_chart_ranking_score_name: (
                            matchedAssessmentScoreTypes[0].description
                          )
                        });
                      }
                      setRankingAssessmentType(`${event.target.value}`);
                    }}
                  >
                    {
                      assessmentScoreTypes.map(({ id, description, longDescription }, index) => (
                        <MenuItem id={`${id}-ranking-score-choice`} key={index} value={id}>{
                          // fall back to `description` if `long_description` is more than 79 chars
                          longDescription.length < 80 ? longDescription : description
                        }</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} justify="center">
        {
          // Only show charts if a ranking assessment type is selected.
          rankingAssessmentType && <>
            <Grid id="current-view-chart" item xs={12} md={6} xl={4}>
              <AssessmentScoreChart
                title="Current view"
                isLoading={specificResponse.isLoading || generalResponse.isLoading}
                response={specificResponse.response}
              />
            </Grid>
            <Grid id="all-colleges-chart" item xs={12} md={6} xl={4}>
              <AssessmentScoreChart
                title="All colleges"
                isLoading={generalResponse.isLoading}
                response={generalResponse.response}
              />
            </Grid>
            <Grid id="comparison-chart" item xs={12} xl={4}>
            {
              // We jump through some hoops here to make sure that AssessmentScoreComparisonChart
              // is never passed inconsistent responses.
              <AssessmentScoreComparisonChart
                title="Comparison with all colleges"
                isLoading={specificResponse.isLoading || generalResponse.isLoading}
                specificResponse={
                  (specificResponse.query === specificQuery) ? specificResponse.response : null
                }
                generalResponse={generalResponse.response}
              />
            }
            </Grid>
          </>
        }
      </Grid>
    </GlobalContextPage>
  );
};

const ChartsPage = () => (
  <DescriptionsConsumer>
      {(descriptions: IDescriptionsResponse | null) => (
        <InnerChartsPage
          descriptions={descriptions}
        />
      )}
  </DescriptionsConsumer>
);

export default ChartsPage;
