// Retrieves and displays a table of applications. Handles paging, sorting, searching, and column selection.

import * as React from 'react';

import {
  applicationList,
  IApplication, IApplicationListResponse,
  IApplicationQuery,
  IDescriptionsResponse, IError, IDescription,
} from "../api";
import { sendAnalytics } from "../utils";
import { Grid, Paper, Theme, createStyles, makeStyles } from "@material-ui/core";
import ApplicationsPageTable, {
  generateAllColumns, IColumnDefinition
} from "../components/ApplicationsPageTable";
import GlobalContextPage from "../containers/GlobalContextPage";
import { DescriptionsConsumer } from 'providers/DescriptionsContextProvider';
import {useGlobalFiltersState} from "../providers/GlobalFiltersProvider";
import {ProfileContext} from '../providers/ProfileProvider';
import {showMessage} from "../containers/Snackbar";
import {createArrayCsvStringifier} from 'csv-writer';
import { useApplicationDetail, useApplications } from "../apiHooks";
import { ApplicationSourceDialog } from "../components/ApplicationSourceDialog";
import { FetchMoreData } from "../components/FetchMoreData";
import ApplicationsPageTableToolbar from 'components/ApplicationsPageTableToolbar';
import ChooseColumnsDialog from '@ucam.uis.devops/choose-columns-dialog';
import { useLocalStorage } from 'react-use';
import { useGlobalContextPageState } from 'providers/GlobalContextPageContextProvider';

const useStyles = makeStyles((theme: Theme) => createStyles({
  tableContainer: {
    overflow: 'auto',
    width: '100%',
    height: '82vh',
  },
}));

/** defines the download page size (the maximum allowed by the API) */
const DOWNLOAD_PAGE_SIZE = 500;
/**
 * The UI limits the number of pages of applications that can be retrieved to this when downloading a CSV.
 * The rationale for 10 is that, at the time of writing, this allows the whole pool to be downloaded.
 */
const DOWNLOAD_PAGE_CAP = 100;
/** The number of applications to fetch in per page. */
const PAGE_SIZE = 50;
/**
 * The keys of columns that `ChooseColumnsDialog` should consider as fixed
 * Note: not all of these columns may be visible in the UI
 */
const FIXED_COLUMN_KEYS = ['name', 'forenames', 'lastname'];
/** The default column selection to use before the user has invoked `ChooseColumnsDialog` */
const INITIAL_SELECT_COLUMN_KEYS = [
  'applicant-files',
  'college',
  'subject',
  'tags',
  'interview-1',
  'interview-2',
  'interview-3',
  'interview-4',
  'at-interview-1',
  'at-interview-2',
  'provisionalCollegeDecision',  // latest decision recorded in SMI
  'collegeDecision',  // decision received from CamSIS
  'college-rank',
  'comments',
];

const INITIAL_QUERY = {
  ordering: 'candidateLastName,candidateForenames',
  page_size: PAGE_SIZE,
};

// The component's properties
interface IProps {
  // descriptions data retrieved from endpoint
  descriptions: IDescriptionsResponse | null;
}

// Mapping of column title to priority (lower value is higher priority) in terms of the order in
// the "Add Column" list of the "Customise Table Columns" dialog. Based on column grouping spec at
// https://miro.com/app/board/o9J_kqNZDFk=/?moveToWidget=3074457364394634250&cot=14
const COLUMN_PRIORITY = Object.fromEntries([
  // Group 0
  'Name', 'Forenames', 'Last Name', 'Applicant Files', 'College', 'Subject', 'Flags',
  'Home Postcode', 'At Interview 1', 'At Interview 2', 'College Decision',
  'Provisional College Decision', 'College Rank', 'Comments',

  // Group 1
  'Preferred Name', 'Gender', 'Birthdate', 'Academic Plan', 'Subject options', 'Original College',
  'Entry Year', 'CamSIS Status',

  // Group 2
  ...[1, 2, 3, 4, 5, 6].map(i => `Interview ${i}`), 'Average Interview Score',
  'LAIAA Score',

  // Group 3
  'Residential Category', 'Country of Domicile', 'POLAR4',

  // Group 4
  'School Name', 'School Type', 'School Postcode', 'School Average Points Score (A Level)',
  'Predicted Grades',

  // Group 4a
  'GCSE School Name', 'GCSE School Postcode', 'GCSE School Average Points Score',
  'Number of A* GCSEs', 'Number of A GCSEs', 'Number of Other GCSEs',
  'Adjusted GCSE Score', 'CoGAF',

  // Group 5
  'TMUA: Overall Score', 'LNAT: LNAT 1 (MCQs)', 'LNAT: LNAT 2 (Essay)', 'CELAT Score',
  'UCAT: Verbal Reasoning', 'UCAT: Decision Making', 'UCAT: Quantitative Reasoning',
  'UCAT: Abstract Reasoning', 'UCAT: Total Score', 'ESAT: Mathematics 1', 'ESAT: Biology',
  'ESAT: Chemistry', 'ESAT: Physics', 'ESAT: Mathematics 2',

  // Group 6
  'Pool Type', 'Pooled Status', 'Pool Interview College 1', 'Pool Interview College 2',
  'Pool outcome', 'Subject Change', 'College Change', 'Entry Year Change',

  // Group 7
  'CamSIS Number', 'UCAS Personal ID', 'USN',
].map((title, index) => ([title, index + 1])));

const InnerApplicationsPage: React.FunctionComponent<IProps> = ({descriptions}) => {
  const profile = React.useContext(ProfileContext);
  const classes = useStyles();
  const {
    applications, replaceQuery, fetchMore, isLoading, hasMore, error, patch, updateError, createPoolOutcome
  } = useApplications();
  const [desiredQuery, setDesiredQuery] = React.useState<IApplicationQuery>(INITIAL_QUERY);
  const globalQuery = useGlobalFiltersState();
  const [sourceDialogOpen, setSourceDialogOpen] = React.useState<boolean>(false)
  const [sourceDialogOpenTime, setSourceDialogOpenTime] = React.useState<Date | null>(null)
  const [applicationDetailState, applicationDetailFetch] = useApplicationDetail();
  const [search, setSearch] = React.useState<string>('');
  const [ordering, setOrdering] = React.useState<IApplicationQuery>({});
  const [downloading, setDownloading] = React.useState(false); // is CSV downloading at the moment?

  // Build a new desired query when any of the component queries change.
  React.useEffect(() => {
    setDesiredQuery({
      ...INITIAL_QUERY,
      ...globalQuery,
      ...ordering,
      ...(search !== '' ? {search} : {}),
    });
  }, [setDesiredQuery, globalQuery, search, ordering]);

  // When the desired query changes, fetch applications. This will clear the current application
  // list and, thereby, scroll to the top of the table.
  React.useEffect(() => {
    replaceQuery(desiredQuery);
  }, [replaceQuery, desiredQuery]);

  // The page state: selected college and subject filter ids and possible subjects
  const state = useGlobalContextPageState();

  // sort the `state.possibleSubjects` and map it to a list of `IDescription` objects with the
  // id and option encoded in the id.
  const subjectsAndOptions: IDescription[] = React.useMemo(() => ((state.possibleSubjects || []).sort(
    ({ description: a }, { description: b }) => a.localeCompare(b)
  ).map(
    ({ id, description, option }) => ({ id: `${id}-${option || ''}`, description })
  )), [state.possibleSubjects])

  // a list of all possible table column definitions (sorted alphabetically and by priority)
  const columns = React.useMemo(() => (
    generateAllColumns(descriptions, patch, createPoolOutcome, subjectsAndOptions).sort(
      ({title: a}, {title: b}) => {
        const priorityA = COLUMN_PRIORITY[a] || 1000000;
        const priorityB = COLUMN_PRIORITY[b] || 1000000;
        return priorityA === priorityB ? 0 : (priorityA < priorityB ? -1 : 1)
      }
    )
  ), [descriptions, patch, createPoolOutcome, subjectsAndOptions]);

  // filter for columns visible in the UI
  const visibleColumns: IColumnDefinition[] = React.useMemo(() =>
      columns.filter(column => column.visibility !== 'download')
    , [columns])

  // Save which columns are displayed to local storage. We form the storage key based on the
  // username of the signed in user to avoid having user's preferences contaminate each other
  // across sign-ins. We have the username last in an attempt to guard against malicious usernames
  // which are chosen to be the prefix of other settings.
  const [savedKeys, setSavedKeys] = useLocalStorage<string[]>(
    `smi/applicationsPageSelectedColumns/v1/${profile ? profile.username : ''}`,
    INITIAL_SELECT_COLUMN_KEYS
  );

  // The savedKeys value is persisted between application sessions. To guard against columns being
  // *removed* from one version of the application to the next and us having stale data, filter the
  // savedKeys value to remove any columns which no-longer exist.
  const selectedKeys = React.useMemo(() => {
    if (savedKeys === undefined) {
      return [];
    }
    const validKeys = new Set(columns.map(({key}) => key));
    return savedKeys.filter(k => validKeys.has(k));
  }, [columns, savedKeys]);

  // the keys of the columns to display in the selected order
  const columnKeys = [...FIXED_COLUMN_KEYS, ...selectedKeys];

  // the selected table columns
  const selectedColumns: IColumnDefinition[] = React.useMemo(() => {
    const columnMap = new Map(columns.map(column => [column.key, column]));
    const selectedColumns: IColumnDefinition[] = [];
    columnKeys.forEach(key => {
      const column = columnMap.get(key);
      column && selectedColumns.push(column)
    });
    return selectedColumns;
  }, [columnKeys, columns]);

  // filter for selected columns visible in the UI
  const visibleSelectedColumns: IColumnDefinition[] = React.useMemo(() =>
    selectedColumns.filter(column => column.visibility !== 'download')
  , [selectedColumns])

  // whether or not the choose columns dialog is open
  const [isChooseColumnsDialogOpen, setChooseColumnsDialogOpen] = React.useState<boolean>(false);
  const [chooseColumnsDialogOpenTime, setChooseColumnsDialogOpenTime] = React.useState<Date | null>(null);

  /** The handler for when the download data button is clicked. */
  const onDownload = (all: boolean) => {
    sendAnalytics('event', 'export_applications_as_csv', {
      export_applications_as_csv_value: true
    });

    // define the list of application fields (based on selectedColumns) to download
    const fields = (all ? columns : selectedColumns)
      // Filter for columns visible when downloading
      .filter(column => column.visibility !== 'ui')
      .flatMap(column => {
        sendAnalytics('event', 'export_applications_as_csv_column', {
          export_applications_as_csv_column_name: column.title
        });
        if (Array.isArray(column.renderForDownload)) {
          // Column maps to multiple sub-columns when downloading, so create a field for each
          // sub-column
          return column.renderForDownload.map(subColumn => ({
            title: subColumn.title, render: subColumn.render
          }));
        } else {
          return [{title: column.title, render: column.renderForDownload || column.render}];
        }
      })

    // use the existing filter as a base for the download fetch
    const downloadQuery = {...desiredQuery, page_size: DOWNLOAD_PAGE_SIZE};

    setDownloading(true);

    // the rendered pre-CSV application data retrieved from the /api
    const renderedData: string[][] = [];

    // handles a page of applications
    const handleResponse = (response: IApplicationListResponse) => {
      // renders the page of applications as a chunk of CSV data
      response.results.forEach(application => (
        renderedData.push(fields.map(field => field.render(application) || ''))
      ));
      const recordsCap = DOWNLOAD_PAGE_CAP * DOWNLOAD_PAGE_SIZE;
      if (response.next && renderedData.length < recordsCap) {
        // if there is more data and we haven't reached the cap then fetch the next page
        applicationList({}, response.next).then(handleResponse).catch(handleError);
      } else {
        // if the requested data is larger than cap then append a line to the CSV explaining this
        if (response.next) {
          renderedData.push([`The download is capped at ${recordsCap} application records`]);
        }
        // convert the rendered data to a CSV
        const csvWriter = createArrayCsvStringifier({
          alwaysQuote: true,
          header: fields.map(field => field.title),
        });
        const csv = `\ufeff${csvWriter.getHeaderString()}${csvWriter.stringifyRecords(renderedData)}`;
        // invoke the download
        const link = document.createElement("a");
        // `encodeURI()` doesn't encode `#`. However an unencoded `#` appears to break the download
        // so we manually encode them.
        // TODO: it may be worth reviewing the use of `encodeURI()` here as some limited testing
        // shows that it downloads fine without it.
        const csvEncoded = encodeURI(csv).replace(/#/g,"%23")
        link.setAttribute("href", `data:text/csv;charset=utf-8,${csvEncoded}`);
        link.setAttribute("download", "applications.csv");
        document.body.appendChild(link);
        link.click();

        setDownloading(false)
      }
    };

    const handleError = (error: IError) => {
      setDownloading(false);
      showMessage(error.error.message);
    };

    // fetch the first page of applications for download
    applicationList(downloadQuery).then(handleResponse).catch(handleError);
  };

  // report any errors fetching the application detail or applications
  React.useEffect(() => (
    applicationDetailState.error &&
    showMessage(`Error retrieving application: ${applicationDetailState.error.error.message}`)
  ), [applicationDetailState.error]);

  React.useEffect(() => (
    error &&
    showMessage(`Error retrieving applications: ${error.error.message}`)
  ), [error]);

  React.useEffect(() => (
    updateError &&
    showMessage(`Error updating application: ${updateError.error.message}`)
  ), [updateError]);

  /** The handler for when the view detail is clicked for an application. */
  const onViewApplication = (application: IApplication) => {
    applicationDetailFetch(application);
    setSourceDialogOpen(true);
  };

  /** Time how long dialogs are open for analytics reporting */
  React.useEffect(() => {
    if (isChooseColumnsDialogOpen && !chooseColumnsDialogOpenTime) {
      setChooseColumnsDialogOpenTime(new Date());
      sendAnalytics('event', 'view_customise_columns_dialog', {
        'view_customise_columns_dialog_value': true
      });
    } else if (!isChooseColumnsDialogOpen && chooseColumnsDialogOpenTime) {
      sendAnalytics('event', 'view_customise_columns_dialog_duration', {
        'view_customise_columns_dialog_msec': (
          (new Date()).getTime() - chooseColumnsDialogOpenTime.getTime()
        )
      });
      setChooseColumnsDialogOpenTime(null);
    }
  }, [chooseColumnsDialogOpenTime, isChooseColumnsDialogOpen]);

  React.useEffect(() => {
    if (sourceDialogOpen && !sourceDialogOpenTime) {
      setSourceDialogOpenTime(new Date());
      sendAnalytics('event', 'view_full_application_details', {
        'view_full_application_details_value': true
      });
    } else if (!sourceDialogOpen && sourceDialogOpenTime) {
      sendAnalytics('event', 'view_full_application_details_duration', {
        'view_full_application_details_msec': (
          (new Date()).getTime() - sourceDialogOpenTime.getTime()
        )
      });
      setSourceDialogOpenTime(null);
    }
  }, [sourceDialogOpen, sourceDialogOpenTime]);

  return <>
    <Grid spacing={2} container justify="center">
      <Grid item xs={12}>
        <Paper>
          <ApplicationsPageTableToolbar
            title='Applications'
            onSearchChanged={(search: string) => {
              sendAnalytics('event', 'search', {
                'search_term': search
              });
              setSearch(search);
            }}
            onChooseColumns={() => {setChooseColumnsDialogOpen(true)}}
            onDownload={(all: boolean) => onDownload(all)}
            isLoading={isLoading || downloading}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.tableContainer}>
          <ApplicationsPageTable
            columns={visibleSelectedColumns}
            applications={applications}
            onOrderChange={setOrdering}
            onViewApplication={onViewApplication}
          />
          <FetchMoreData
            hasMore={hasMore}
            isFetching={isLoading}
            onFetchMore={fetchMore}
          />
        </Paper>
      </Grid>
    </Grid>
    <ApplicationSourceDialog
      open={sourceDialogOpen}
      onClose={() => setSourceDialogOpen(false)}
      isLoading={applicationDetailState.isLoading}
      application={applicationDetailState.application}
    />
    <ChooseColumnsDialog
      open={isChooseColumnsDialogOpen}
      onCancel={() => setChooseColumnsDialogOpen(false)}
      onClose={() => setChooseColumnsDialogOpen(false)}
      onSetColumns={
        (newSelectedKeys: string[]) => {
          sendAnalytics('event', 'change_applications_columns', {
            change_applications_columns_value: true
          });

          // Determine changes to columns for analytics reporting
          const newSelectedKeysSet = new Set(newSelectedKeys);
          const selectedKeysSet = new Set(selectedKeys);
          const addedColumns = new Set(
            newSelectedKeys.filter(x => !selectedKeysSet.has(x))
          );
          const removedColumns = new Set(
            selectedKeys.filter(x => !newSelectedKeysSet.has(x))
          );

          const columnMap = new Map(
            columns.map((column: IColumnDefinition) => [column.key, column])
          );
          addedColumns.forEach(key => {
            const column = columnMap.get(key);
            column && sendAnalytics('event', 'add_applications_column', {
              'add_applications_column_name': column.title
            });
          });
          removedColumns.forEach(key => {
            const column = columnMap.get(key);
            column && sendAnalytics('event', 'remove_applications_column', {
              'remove_applications_column_name': column.title
            });
          });
          newSelectedKeys.forEach(key => {
            const column = columnMap.get(key);
            column && sendAnalytics('event', 'set_applications_column', {
              'set_applications_column_name': column.title
            });
          });

          // Store the new columns so they will get applied to the view
          setSavedKeys(newSelectedKeys);
          setChooseColumnsDialogOpen(false);
        }
      }
      columns={visibleColumns.map(({key, title, secondaryText}) => ({
        key, primaryText: title, secondaryText, autocompleteOptionValue: title
      }))}
      initialSelectedColumnKeys={selectedKeys}
      fixedColumnKeys={FIXED_COLUMN_KEYS}
      DialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        scroll: 'paper',
      }}
    />
  </>
};

const ApplicationsPage = () => (
  <GlobalContextPage>
    <DescriptionsConsumer>{
      (descriptions: IDescriptionsResponse | null) => (
        <InnerApplicationsPage descriptions={descriptions}/>
      )
    }</DescriptionsConsumer>
  </GlobalContextPage>
);

export default ApplicationsPage;
