// Utility functions used across the app

/**
 * Provide analytics function if it has been defined in a top-level snippet,
 * otherwise create an empty function so the app can still call it.
 *
 * Ensure that analytics have been configured before any other analytics are sent.
 */
const preUserList: any = [];
let configSent = false;
let userPropertiesSent = false;
const sendAnalytics = (
  // @ts-ignore
  window['gtag'] ?
  function(command: string, option1: any, option2?: any) {
    // @ts-ignore
    const gtag = window['gtag'];

    // If analytics have already been configured then call `gtag()` immediately and return
    if (configSent && userPropertiesSent) {
      gtag(command, option1, option2);
      return;
    }

    const sendConfigOperation = command === 'config' && 'user_id' in option2;
    if (!configSent && sendConfigOperation) {
      configSent = true;
      gtag(command, option1, option2);
    }

    const sendUserPropertiesOperation = command === 'set' && option1 === 'user_properties';
    if (!userPropertiesSent && sendUserPropertiesOperation) {
      userPropertiesSent = true;
      gtag(command, option1, option2);
    }

    if (configSent && userPropertiesSent) {
      // Once analytics have been configured, clear the queue of any pending operations
      while(preUserList.length > 0) {
        const operation = preUserList.shift();
        gtag(...operation)
      }
    } else if (!sendConfigOperation && !sendUserPropertiesOperation) {
      preUserList.push([command, option1, option2]);
    }
  }
  : function(){}
);

/** Use subtle crypto API to generate a SHA256 hash */
async function sha256(message: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

export {
  sendAnalytics,
  sha256
};
