/**
 * Context for all state associated with the GlobalContextPage component.
 * To update the state, dispatch() is used
 */
import * as React from 'react';
import {useApplicationCounts} from "../apiHooks";
import {IDescription} from "../api";

interface ISetSubjectFilterAction {
  type: 'SET_SUBJECT_FILTER'
  payload?: string
}

interface ISetCollegeFilterAction {
  type: 'SET_COLLEGE_FILTER'
  payload?: string[]
}

interface ISetFlagFilterAction {
  type: 'SET_FLAG_FILTER'
  payload?: string[]
}

interface ISetPoolTypeFilterAction {
  type: 'SET_POOL_TYPE_FILTER'
  payload?: string[]
}

interface ISetPoolStatusFilterAction {
  type: 'SET_POOL_STATUS_FILTER'
  payload?: string[]
}

interface ISetDecisionFilterAction {
  type: 'SET_DECISION_FILTER'
  payload?: string[]
}

/** The external action to be used with dispatch() */
export type IGlobalContextPageContextAction = (
    ISetSubjectFilterAction |
    ISetCollegeFilterAction |
    ISetFlagFilterAction |
    ISetPoolTypeFilterAction |
    ISetPoolStatusFilterAction |
    ISetDecisionFilterAction
)

/** A subject filter with an optional subject option */
interface ISubject extends IDescription {
  option?: string;
}

interface ISetPossibleSubjectsAction {
  type: 'SET_POSSIBLE_SUBJECTS'
  payload: ISubject[]
}

/**
 * The internal action to be used with dispatch() with additional action SET_POSSIBLE_SUBJECTS
 */
type IInnerGlobalContextPageContextAction = (
  IGlobalContextPageContextAction | ISetPossibleSubjectsAction
)

/** A custom type defining the state for GlobalContext */
interface IGlobalContextPageState {
  /** The possible subjects that can be filtered on - populated by fetchCounts() */
  possibleSubjects?: ISubject[]
  /** The id of the selected subject filter */
  subjectFilter?: string
  /** The ids of the selected college filter */
  collegeFilter?: string[]
  /** The ids of the selected annotations filter */
  flagFilter?: string[]
  /** The ids of the selected pool types filter */
  poolTypeFilter?: string[]
  /** The ids of the selected pool statuses filter */
  poolStatusFilter?: string[]
  /** The ids of the selected decision filter */
  decisionFilter?: string[]
}

const INITIAL_STATE = {};

const GlobalContextPageStateContext = React.createContext<IGlobalContextPageState>(INITIAL_STATE);
const GlobalContextPageDispatchContext = React.createContext(
  (action: IInnerGlobalContextPageContextAction) => {}
);

/** reducer to perform any state changes */
const reducer = (state: IGlobalContextPageState, action: IInnerGlobalContextPageContextAction): IGlobalContextPageState => {
  switch(action.type) {
    // sets the possible subjects
    case 'SET_POSSIBLE_SUBJECTS':
      return {...state, possibleSubjects: action.payload};
    // sets id of the selected subject filter
    case 'SET_SUBJECT_FILTER':
      return {...state, subjectFilter: action.payload};
    // sets id of the selected college filter
    case 'SET_COLLEGE_FILTER':
      return {...state, collegeFilter: action.payload};
    // sets id of the selected flag filter
    case 'SET_FLAG_FILTER':
      return {...state, flagFilter: action.payload};
    // sets id of the selected pool type filter
    case 'SET_POOL_TYPE_FILTER':
      return {...state, poolTypeFilter: action.payload};
    // sets id of the selected pool status filter
    case 'SET_POOL_STATUS_FILTER':
      return {...state, poolStatusFilter: action.payload};
    case 'SET_DECISION_FILTER':
      return {...state, decisionFilter: action.payload};
  }
};

/**
 * This dictionary defines additional subject option filters. An entry is keyed on subject id and
 * has a list of the subject options to provide filters for.
 */
const SUBJECT_FILTERS_WITH_OPTIONS: {[index: string]: string[]} = {
  "NSTX": ["Biological", "Physical"]
};

export const GlobalContextPageContextProvider: React.FunctionComponent = ({ children }) => {

  /** Creates the GlobalContextPage state. */
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  const [{ response }, fetchCounts] = useApplicationCounts();

  // retrieve the application counts by subject
  React.useEffect(() => {
    !response && fetchCounts({
      includeAnnotations: false,
      secondAxis: 'subject',
    }
  )},[fetchCounts, response]);

  // set the possible subjects using the application counts by subject
  React.useEffect(() => {
    if (response) {
      const possibleSubjects: ISubject[] = [];

      response.secondAxisCounts.forEach(secondAxisCount => {
        const subject = {id: secondAxisCount.secondAxisId, description: secondAxisCount.secondAxisDescription};
        possibleSubjects.push(subject);
        // if additional subject option filters are required..
        if (SUBJECT_FILTERS_WITH_OPTIONS[subject.id]) {
          // .. create these filters
          possibleSubjects.push(...SUBJECT_FILTERS_WITH_OPTIONS[subject.id].map((option) => (
            {id: subject.id, description: `${subject.description} (${option})`, option: option}
          )))
        }
      });

      dispatch({type: 'SET_POSSIBLE_SUBJECTS', payload: possibleSubjects})
    }
  }, [response]);

  return <GlobalContextPageStateContext.Provider value={state}>
    <GlobalContextPageDispatchContext.Provider value={dispatch}>{
      children
    }</GlobalContextPageDispatchContext.Provider>
  </GlobalContextPageStateContext.Provider>;
};

export const useGlobalContextPageState = () => React.useContext(GlobalContextPageStateContext);
export const useGlobalContextPageDispatch = () => React.useContext(GlobalContextPageDispatchContext);
