import * as React from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import {createStyles, Theme, WithStyles, withStyles} from '@material-ui/core/styles';

// Define properties which must be present on the option objects being passed to this component.
export interface Option {
  /** Some key which is used to identify the option. */
  key: string;
  /** Primary text of the option */
  text: React.ReactNode;
  /** Secondary text that describes the option in greater detail */
  description?: React.ReactNode;
  disabled?: boolean
}

const styles = (theme: Theme) => createStyles({
  title: {
    paddingBottom: theme.spacing(0),
  },
  selectAllNone: {
    flexShrink: 0,
    minHeight: 30,
    overflowY: 'hidden',
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

export interface MultiSelectDialogProps extends WithStyles<typeof styles> {
  id: string;
  // Flag indicating if the dialog is shown to the user. Set on the underlying Dialog component.
  open: boolean;
  // Array of option descriptions. See the Option interface for more details.
  options?: Option[];

  // List of selected option keys.
  initialSelectedKeys?: string[];

  // Function called when the user selects a new column layout.
  onSaveSelectedKeys?: (selectedKeys: string[]) => void;

  // Function called when the user closes the dialog by some other means (e.g. by clicking away).
  // Set on the underlying Dialog component.
  onClose: DialogProps["onClose"],

  // Function called when the user explicitly cancels the dialog box via the "Cancel" button.
  onCancel?: () => void;

  // Title of dialog
  title: string;

  // Description of the purpose of the dialog (displayed under the title)
  description?: string;
}

/**
 * The MultiSelectDialog component allows the selection of multiple options in a dialog box.
 *
 * The component manages its own UI state and communicates via a series of event handler groups.
 * Users of the component don't get notified as the user add or removes options and
 * they only get notified once the user has selected the options
 */
export const MultiSelectDialog = withStyles(styles)((
  {
    id = 'select-options',
    classes,
    open,
    onClose = () => null,
    onCancel = () => null,
    onSaveSelectedKeys = selectedKeys => null,
    options = [],
    initialSelectedKeys = [],
    title = 'Select Options',
    description,
  }: MultiSelectDialogProps
) => {
  const [selectedKeys, setSelectedKeys] = React.useState<Set<string>>();

  React.useEffect(() => {
    if (!selectedKeys) {
      setSelectedKeys(new Set(initialSelectedKeys))
    }
  }, [initialSelectedKeys, selectedKeys]);

  const toggleOption = (key:string) => () => {
    const newState = new Set(selectedKeys);
    if (newState.has(key)) {
      newState.delete(key);
    } else {
      newState.add(key)
    }
    setSelectedKeys(newState);
  };

  return <Dialog
    id={id}
    open={open}
    onClose={onClose}
    onExited={() => setSelectedKeys(new Set(initialSelectedKeys))}
    aria-labelledby={`${id}-title`}
    data-role="multiSelectDialog"
  >
    <DialogTitle id={`${id}-title`} data-role="title" classes={{root: classes.title}}>{title}</DialogTitle>
    <DialogContent classes={{root: classes.selectAllNone }}>
      {description &&
        <DialogContentText data-role="description">
          {description}
        </DialogContentText>
      }
      <ButtonGroup variant="outlined" size="small" fullWidth>
        <Button data-role="selectNone" color="primary" onClick={() => setSelectedKeys(new Set())}>
          Select None
        </Button>
        <Button data-role="selectAll" color="primary" onClick={() => setSelectedKeys(new Set(options.map((o:Option) => o.key)))}>
          Select All
        </Button>
      </ButtonGroup>
    </DialogContent>
    <DialogContent dividers={true} classes={{root: classes.content}}>
      <List>
        {
          options
          .map((option: Option) => (
            <ListItem
              data-role="option" data-key={option.key}
              disabled={!!option.disabled}
              key={option.key} dense button onClick={toggleOption(option.key)}
            >
              <ListItemIcon>
                <Checkbox
                  data-role="checkbox"
                  edge="start"
                  checked={selectedKeys && selectedKeys.has(option.key)}
                  disableRipple
                  disabled={option.disabled || false}
                  inputProps={{ 'aria-labelledby': `checkbox-label-${option.key}`}}
                />
              </ListItemIcon>
              <ListItemText
                data-role="description"
                primary={option.text}
                secondary={option.description}
              />
            </ListItem>
          ))
        }
        </List>
    </DialogContent>
    <DialogActions>
      <Button data-role="cancel" onClick={() => onCancel()}>
        Cancel
      </Button>
      <Button data-role="apply" color="primary" onClick={() => onSaveSelectedKeys(!selectedKeys ? [] : Array.from(selectedKeys))}>
        Apply
      </Button>
    </DialogActions>
  </Dialog>
});

export default MultiSelectDialog;
