import * as React from 'react';

import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core/styles";

import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * A loading indicator which can be used to show loading progress.
 */

const styles = (theme: Theme) => createStyles({
  div: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
});

export const LoadingIndicator = withStyles(styles)(({ classes }: WithStyles<typeof styles> ) =>
  <div className={ classes.div }>
    <CircularProgress />
  </div>
);

export default LoadingIndicator;
