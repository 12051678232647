import * as React from 'react';

import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import AppBar from '@ucam.uis.devops/app-bar';
import NavigationPanel from '../components/NavigationPanel';

import {ProfileContext} from '../providers/ProfileProvider';
import LoadingIndicator from '../components/LoadingIndicator';
import BannerMessages from '../components/BannerMessages';

import { drawerWidth } from '../theme';
import signInUrl from "../signInUrl";
import { useBannerMessageList } from '../apiHooks';

const styles = (theme: Theme) => createStyles({
  // Add padding for elements below the drawer on sufficiently large screens.
  drawerPadded: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: drawerWidth,
    },
  },

  drawerPaper: { width: drawerWidth },

  children: {
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    }
  },

  toolbar: theme.mixins.toolbar,
});

interface IProps extends WithStyles<typeof styles> {
  /** Children of the top-level AppBar content. */
  appBarChildren?: React.ReactNode;
  /** Page can be viewed by anonymous user */
  allowAnonymous?: boolean;
}

/**
 * A top level component that wraps all pages to give then elements common to all page,
 * the ``AppBar`` etc.
 */
const Page: React.FunctionComponent<IProps> =
  ({ children, appBarChildren, allowAnonymous = false, classes }) => {

  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false)

  const profile = React.useContext(ProfileContext);

  const { response: { results: bannerMessages = [] } = {} } = useBannerMessageList();

  // anonymous users not permitted so redirect them the sign in url
  if (!allowAnonymous && profile && profile.isAnonymous) {
    window.location.href = signInUrl();
    return null;
  }

  const handleDrawerToggle = () => setMobileDrawerOpen(prev => !prev);

  return (
    <>
      <AppBar
        position="fixed"
        classes={{root: classes.drawerPadded}}
        onMenuClick={ handleDrawerToggle }
        children={ appBarChildren }
      />

      <Hidden lgUp={ true }>
        <Drawer
          variant="temporary"
          open={ mobileDrawerOpen }
          onClose={ handleDrawerToggle }
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavigationPanel />
        </Drawer>
      </Hidden>

      <Hidden mdDown={ true } implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <NavigationPanel />
        </Drawer>
      </Hidden>

      <Box minHeight="100vh" bgcolor="action.hover">
        <div className={ classes.drawerPadded }>
          { /* used as a spacer */ }
          <div className={ classes.toolbar } />

          <BannerMessages messages={bannerMessages} />

          <div className={ classes.children }>
            { !profile ? <LoadingIndicator /> : children }
          </div>
        </div>
      </Box>
    </>
  );
};

export default withStyles(styles)(Page);
