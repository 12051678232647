import * as React from 'react';

import { green, yellow, orange, red, brown, grey } from '@material-ui/core/colors';
import { BarSvgProps, BarDatum } from '@nivo/bar';

import { IAssessmentScoreHistogramResponse } from 'api';
import AutoSizedBarChart from '../components/AutoSizedBarChart';
import { fromPairs } from 'lodash';

export interface DecisionGroupedHistogramProps {
  /** Histogram API response to render or null if there is not yet a response. */
  response?: IAssessmentScoreHistogramResponse | null;

  /** Props set on underlying Bar component. */
  BarProps?: BarSvgProps;
}

/**
 * Histogram comparing distribution of scores in assessmentScoreHistogram responses broken down by
 * latest decision. The chart is responsive and will fill the parent.
 */
export const DecisionGroupedHistogram: React.FunctionComponent<DecisionGroupedHistogramProps> = (
  { BarProps, response = null }
) => {
  // Compute props for the Bar component which depend on the response. We use useMemo
  // here to avoid re-computing the props when props other than the response change.
  const computedProps = React.useMemo<BarSvgProps>(() => {
    // If there is not yet any response, there are no computed props. Return only the props which
    // are required to be defined on the Bar component.
    if(response === null) { return {keys: [], data: []}; }

    // Construct a mapping between decision type id and the description.
    const decisionDescriptionById = new Map(
      response.secondAxisValues.map(({ id, description }) => [id, description])
    );

    // Construct the keys for the decisions we want to display on the graph. Due to the way that
    // the legend is rendered, the keys appear in the *reverse* order of the keys Array. Also, due
    // to the way nivo works, the keys array needs to be the human-friendly descriptions of the
    // decisions, not their ids.
    const keyDecisionIds = [
      'WITHDRAWN', 'DESELECT', 'REJECT', 'POOL_TAG', 'POOL', 'CONDITIONAL', 'OFFER',
      'UNDECIDED', NO_DECISION_KEY
    ];
    const keys = keyDecisionIds.map(id => decisionDescriptionById.get(id) || id);

    // Construct a mapping from decision type *description* to colour. We need this mapping since
    // we have to use decision type descriptions in the data. This is, in turn, because nivo
    // doesn't yet allow custom legend formatting functions.
    const decisionDescriptionToColour = new Map(
      response.secondAxisValues.map(
        ({ id, description }) => ([description, DECISION_PALETTE.get(id) || FALLBACK_COLOUR])
      )
    );

    // A custom colour mapping function for assigning a friendly palette to decision types. Note
    // that the prop takes the US spelling. It's no wonder that George III went mad.
    const colors = ({ id }: BarDatum) => (
      // NB: we ensure id is a string here because BarDatum allows id to be numeric.
      decisionDescriptionToColour.get(`${id}`) || FALLBACK_COLOUR
    );

    // Construct a pattern fills object. Again, we need to do this here because nivo uses the
    // human-friendly descriptions as keys. We make sure we only define fills for decision types we
    // have descriptions for. TypeScript doesn't notice this, hence the type assertion.
    const fill =
      PATTERN_DEFS
      .filter(({ id }) => decisionDescriptionById.has(id))
      .map(({ id }) => ({ match: { id: (decisionDescriptionById.get(id) as string) }, id }));

    // The bottom axis has a legend taken from the assessment type description or "Score" if the
    // assessment type description is not returned in the response.
    const axisBottom: BarSvgProps["axisBottom"] = {
      legend: (
        response.rankingAssessmentType ? response.rankingAssessmentType.description : 'Score'
      ),
    };

    // Compute the maximum total count value or zero if there are no counts. We filter out buckets
    // for no-data or the out of bound buckets as we do not display those.
    const maximumCount = response.valueBucketCounts
      .filter(({ interval: { low, high }}) => (low !== null) && (high !== null))
      .reduce((accumulator, { count }) => Math.max(accumulator, count), 0);

    // We want the left axis to have at most 2 significant figures. Compute the logarithm of the
    // scale multiplier required for this. Ensure that the maximum is at least 1 to avoid trying
    // to take the log of zero.
    const leftAxisMultiplier = Math.pow(10, Math.max(
      0, Math.ceil(Math.log10(Math.max(1, maximumCount))) - 2
    ));

    // Settings for the left axis.
    const axisLeft: BarSvgProps["axisLeft"] = {
      legend: 'Count',
      format: value => `${parseFloat(((value as number) / leftAxisMultiplier).toFixed(1))}`,
    };

    // If the left axis multiplier is not unity, append a "(100...s)" parenthetical to the axis
    // legend with the appropriate number of zeros.
    if(leftAxisMultiplier !== 1) {
      axisLeft.legend = `${axisLeft.legend} (${leftAxisMultiplier.toLocaleString()}s)`;
    }

    // Massage the data from the response into an appropriate set of data for passing to the chart.
    // Somewhat annoyingly, nivo requires that the keys used for data are human-friendly since they
    // appear in the legend and are used for the tick marks.
    //
    // Ultimately, the object looks like the following:
    //
    //  [
    //    {
    //      id: '<tick mark label>',
    //
    //      [NO_DECISION_KEY]: <count where latest decision is null>,
    //
    //      // For each decision type (even if not present in response)...
    //      '<human friendly decision label>': <count for decision>,
    //    },
    //    // ... etc
    //  ]
    const data = (
      response.valueBucketCounts
      // We filter out the "no data" and out of bounds values here
      .filter(({ interval: { low, high } }) => (low !== null) && (high !== null))
      .map(({ interval: { low, high }, secondAxisCounts }) => {
        // Construct a mapping between decision type id and the count returned in the response.
        const countById = new Map(
          secondAxisCounts.map(({ id, count }) => [id, count])
        );

        // The base datum contains the id (which becomes the tick label) and the no-decision count.
        const baseDatum: BarDatum = {
          id: `${0.5 * ((low as number) + (high as number))}`,
          [NO_DECISION_KEY]: countById.get(null) || 0,
        };

        // Add a count to the datum for each decision type. Note that this will use "0" as a
        // placeholder for all decision types not returned in the response.
        return {
          ...baseDatum,
          ...(
            fromPairs(
              response.secondAxisValues.map(
                ({ id }) => (
                  [
                    decisionDescriptionById.get(id) || id,
                    countById.get(id) || 0,
                  ]
                )
              )
            )
          ),
        };
      })
    );

    return { keys, data, axisBottom, axisLeft, colors, fill };
  }, [response]);

  return <AutoSizedBarChart
    defs={PATTERN_DEFS}
    {...computedProps}
    {...BarProps}
  />;
};

export default DecisionGroupedHistogram;

// Constants used by the component.

// A key (and lebel) used to indicate a null decision type.
const NO_DECISION_KEY = 'No decision';

// Fallback "undecided" colour when we don't have a set palette colour.
const FALLBACK_COLOUR = grey[300];

// A palette of colours to use for each decision type.
const DECISION_PALETTE = new Map([
  ['OFFER', green[500]],
  ['CONDITIONAL', green[900]],
  ['POOL', orange[500]],
  ['POOL_TAG', yellow[500]],
  ['REJECT', red[600]],
  ['DESELECT', red[900]],
  ['WITHDRAWN', brown[500]],
  ['UNDECIDED', grey[500]],
]);

const baseDotsPattern = {
  type: 'patternDots', background: 'inherit', color: 'rgba(0, 0, 0, 0.26)',
  size: 2, padding: 2, stagger: true,
};

const baseLinesPattern = {
  type: 'patternLines', background: 'inherit', color: 'rgba(0, 0, 0, 0.26)',
  spacing: 8, lineWidth: 2,
};

// For accessibility, also use a different pattern for each decision type.
const PATTERN_DEFS = [
  { id: 'OFFER', ...baseDotsPattern },
  { id: 'CONDITIONAL', ...baseDotsPattern, color: 'rgba(255, 255, 255, 0.26)' },
  { id: 'POOL', ...baseLinesPattern, rotation: 45 },
  { id: 'POOL_TAG', ...baseLinesPattern, rotation: 45 },
  { id: 'REJECT', ...baseLinesPattern, rotation: -45, color: 'rgba(255, 255, 255, 0.26)' },
  { id: 'DESELECT', ...baseLinesPattern, rotation: -45, color: 'rgba(255, 255, 255, 0.26)' },
  { id: 'WITHDRAWN', ...baseLinesPattern, rotation: 90, color: 'rgba(255, 255, 255, 0.26)' },
];
