import {IApplication, IPoolOutcomeCreate, IDescription} from "../api";
import * as React from "react";
import {createStyles, MenuItem, Select, Theme, WithStyles, withStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  fontSize: {
    fontSize: '0.875rem',
  },
  width: {
    width: '100%'
  },
});

/**
 * Properties for PoolOutcomeSubjectSelect
 */
interface IPoolOutcomeSubjectSelectProps extends WithStyles<typeof styles> {
  /** 
   * The list of currently used subjects with any subject objects that are valid for the subject.
   * The id should be of the form {subjectId}-{subjectOption}.
   */
  subjectsAndOptions: IDescription[];
  /** The target application */
  application: IApplication;
  /** The handler for when a subject (and possibly option) is selected */
  onPoolOutcomeCreate: (poolOutcome: IPoolOutcomeCreate) => void;
  /** To allow better external visbility, if set, will add a 'data-role' property to the input */
  dataRole?: string;
}

/**
 * Component to render a input select for a pool outcome subject (and possibly option). When a selection
 * is made the component dispatches a pool outcome creation event updating the targeted field.
 */
const PoolOutcomeSubjectSelect: React.FunctionComponent<IPoolOutcomeSubjectSelectProps> = ({
  subjectsAndOptions, application, onPoolOutcomeCreate, dataRole, classes
}) => {

  const inputProps:any = {};
  if (dataRole) {
    inputProps['data-role'] = dataRole
  }

  // handler for select changes events - dispatches an `IPoolOutcomeCreate` populated by
  // `latestPoolOutcome` and the selected value.
  const onChange = (event: any) => {
    const [subject, subjectOption] = event.target.value.split('-');
    // TODO we note here that the API allows you to select multiple subject options (as you might
    // expect) however the current component limits you to a very limited sub-set of subject/option
    // combinations as defined by the `subjectsAndOptions` list. This is sub-optimal and we expect
    // to refactor this in the future.
    const poolOutcomeCreate: any = {
      status: null,
      ...application.latestPoolOutcome || {},
      applicationId: application.camsisApplicationNumber,
      subject,
      subjectOptions: (subjectOption && [subjectOption]) || []
    };
    onPoolOutcomeCreate(poolOutcomeCreate);
  };

  const latestPoolOutcome = application.latestPoolOutcome;

  // create the current value from the `subject` & `subjectOptions`
  let value = (
    latestPoolOutcome && `${latestPoolOutcome.subject}-${latestPoolOutcome.subjectOptions.join('')}`
  );

  // If we don't find the particular subject/options combination in the `subjectsAndOptions` list,
  // we fallback to the subject with no options.
  // TODO this really isn't great because if the user changes from a subject with some unsupported
  // options and then reverts the change they will lose the options.
  if (!subjectsAndOptions.find(({id}) => id === value)) {
    value = latestPoolOutcome && `${latestPoolOutcome.subject}-`
  }

  return (
    <Select 
      inputProps={inputProps} value={value || ''} onChange={onChange}
      classes={{root: classes.fontSize}} className={classes.width}
    >{
      subjectsAndOptions.map(({id, description}) => (
        <MenuItem className={classes.fontSize} key={id} value={id}>{description}</MenuItem>
      ))
    }</Select>
  )
};

export default withStyles(styles)(PoolOutcomeSubjectSelect);
