import * as React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) => createStyles({
  profileBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
  },

  profileUsernameContainer: {
    display: 'flex',
  },

  profileUsername: {
    flexGrow: 1,
  },
});

export interface IProfile {
  /** The user's id */
  username: string;
  /** The user's display name */
  displayName: string;
  /** The user's profile image source */
  avatarUrl: string | null;
}

export interface INavigationPanelAvatarProps extends WithStyles<typeof styles> {
  /** An object repesenting the user's profile */
  profile: IProfile
}

/**
 * A component that represents a given user's profile. It is designed to be used as a child
 * component of the `NavigationPanel` component.
 */
export const NavigationPanelAvatar = withStyles(styles)(({ profile, classes }: INavigationPanelAvatarProps) => (
  <div className={ classes.profileBar }>
      <Avatar
          alt={ profile.displayName }
          src={ profile.avatarUrl ? profile.avatarUrl : undefined }
      >
          { profile.avatarUrl ? null : profile.displayName[0] }
      </Avatar>
      <Typography variant='h6'>{ profile.displayName }</Typography>
      <div className={ classes.profileUsernameContainer }>
      <Typography variant='caption' className={ classes.profileUsername }>
          { profile.username }
      </Typography>
      </div>
  </div>
));

export default NavigationPanelAvatar;
